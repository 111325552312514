import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import AppContext from '../AppContext';

const loadOptions = inputValue => {
  //let result = await axios(`${AppContext.apiUrl}/api/makes`)
  return fetch(`${AppContext.apiUrl}/api/models/search/${inputValue}`)
    .then(res => res.json())
    .then(res => {
      return res.map(item => ({
        label: item.name,
        value: item.id,
      }));
    });
};

const debouncedLoadOptions = debounce(loadOptions, 800, {
  leading: true
});

const ModelSelectionComponent = ({ selectedValue,onSelectionChange }) => {
  return (
    <AsyncSelect
      value={selectedValue}
      cacheOptions
      defaultOptions
      loadOptions={debouncedLoadOptions}
      onChange={onSelectionChange}  // Pass the selected value to the parent's handler
    />
  );
};

export default ModelSelectionComponent;
