import React from 'react';
import AppContext from "../../AppContext"
import { useSelector } from 'react-redux';


const DownloadAttachment = ({ messageId, attachmentId, fileName }) => {

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token

    const handleDownload = () => {
        const apiEndpoint = `${AppContext.apiUrl}/api/admin/gmailtool/attachments/${messageId}/${attachmentId}?fileName=${fileName}`;

        // Fetch the attachment from the API
        fetch(apiEndpoint, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        })
        .then(response => {
            if (response.ok) {
                return response.blob();  // Get the response as a Blob
            }
            throw new Error('Network response was not ok.');
        })
        .then(blob => {
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;

            // Use the provided fileName prop or default to 'attachment'
            a.download = fileName || 'attachment';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);  // Remove the element after the click
        })
        .catch(error => {
            console.error('There was a problem with the download:', error);
        });
    };

    return (
      <div>
        <button onClick={handleDownload}>
            Download {fileName}
        </button>
        </div>
    );
};

export default DownloadAttachment;
