import React, { useState } from 'react';

function CheckboxGroup() {
  // State for the types and their checked status
  const [types, setTypes] = useState({
    A: false,
    B: false
  });

  // State for all subtypes
  const [subtypes, setSubtypes] = useState([
    { id: '1', label: 'Subtype 1', type: 'A', checked: false, enabled: false },
    { id: '2', label: 'Subtype 2', type: 'A', checked: false, enabled: false },
    { id: '3', label: 'Subtype 3', type: 'A', checked: false, enabled: false },
    { id: '4', label: 'Subtype 4', type: 'B', checked: false, enabled: false },
    { id: '5', label: 'Subtype 5', type: 'B', checked: false, enabled: false },
    { id: '6', label: 'Subtype 6', type: 'B', checked: false, enabled: false }
  ]);

  // Handle change for type checkboxes
  const handleTypeChange = (type) => {
    const newState = !types[type];
    setTypes({
      ...types,
      [type]: newState
    });

    // Enable all subtypes associated with this type without checking them
    setSubtypes(subtypes.map(subtype =>
      subtype.type === type ? { ...subtype, enabled: newState } : subtype
    ));
  };

  // Handle change for subtype checkboxes
  const handleSubtypeChange = (id) => {
    setSubtypes(subtypes.map(subtype =>
      subtype.id === id ? { ...subtype, checked: !subtype.checked } : subtype
    ));
  };

  // Render checkboxes
  return (
    <div>
      <div>
        <label>
          <input
            type="checkbox"
            checked={types['A']}
            onChange={() => handleTypeChange('A')}
          />
          Type A
        </label>
        <div>
          {subtypes.filter(c => c.type === 'A').map((item) => (
            <label key={item.id}>
              <input
                type="checkbox"
                checked={item.checked}
                onChange={() => handleSubtypeChange(item.id)}
                disabled={!item.enabled}  // Enable based on the `enabled` property
              />
              {item.label}
            </label>
          ))}
        </div>
      </div>

      <div>
        <label>
          <input
            type="checkbox"
            checked={types['B']}
            onChange={() => handleTypeChange('B')}
          />
          Type B
        </label>
        <div>
          {subtypes.filter(c => c.type === 'B').map((item) => (
            <label key={item.id}>
              <input
                type="checkbox"
                checked={item.checked}
                onChange={() => handleSubtypeChange(item.id)}
                disabled={!item.enabled}  // Enable based on the `enabled` property
              />
              {item.label}
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CheckboxGroup;
