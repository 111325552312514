import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./home.css"
import axios from "axios"
import { Stack } from "@mui/material"
import { Autocomplete, Box, Button, Checkbox, Collapse, Divider, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from "@mui/material"
import { Add, Remove } from "@mui/icons-material"
import background from "./AdobeStock_364591828cp.png"
import MUIDataTable from "mui-datatables"
import { Avatar } from "@material-ui/core"
import AppContext from "../../AppContext"
import { FormGroup, FormLabel, FormControlLabel, Grid } from "@mui/material"

import Menu from "@mui/material/Menu"
import Navbar from "../navbar/Navbar"
import { encodeNumberTo10CharString } from "../../NumberCodec"

function Home(props) {
  //start search filter
  //for make filter
  const [filterText, setFilterText] = useState("")
  const filters = [
    {
      label: "Make",
      type: "dropdown",
      options: ["3D PLUS", "3M", "AB SCIEX / APPLIED BIOSYSTEMS / ABI / MDS SCIEX"]
    },
    {
      label: "Model",
      type: "autocomplete",
      options: ["PI 9500", "9500xR", "xR80", "Quantum", "Quantum Leap"]
    },
    {
      label: "Category",
      type: "autocomplete",
      options: ["Ion Implanters"]
    }
    // {
    //   label: "Screen size",
    //   type: "checkbox",
    //   options: ["13in", "14in", "15.6in", "Over 15.6in"]
    // },
    // {
    //   label: "Price",
    //   type: "checkbox",
    //   options: ["< $1000", "$1000-$1400", "$1400-$2000", "> $2000"],
    //   required: true
    // },
    // {
    //   label: "Frame refresh rate",
    //   type: "checkbox",
    //   options: ["61-99 Hz", "100-120 Hz", "121-144 Hz", "145-240 Hz"]
    // },
    // {
    //   label: "RAM",
    //   type: "checkbox",
    //   options: ["< 2GB", "2-4 GB", "4-8 GB", "8-16 GB"]
    // }
  ]

  const [openFilters, setOpenFilters] = React.useState({})
  const [filterValues, setFilterValues] = React.useState({})
  

  const handleToggleFilter = filterLabel => {
    setOpenFilters(prevOpenFilters => ({
      ...prevOpenFilters,
      [filterLabel]: !prevOpenFilters[filterLabel]
    }))
  }

  const handleFilterChange = event => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [event.target.name]: event.target.value
    }))
  }

  const handleAutocompleteChange = (event, value, filterLabel) => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [filterLabel]: value
    }))
  }

  const handleCheckboxChange = event => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [event.target.name]: event.target.checked ? [...(prevFilterValues[event.target.name] || []), event.target.value] : prevFilterValues[event.target.name].filter(value => value !== event.target.value)
    }))
  }

  const handleResetAll = () => {
    setOpenFilters({})
    setFilterValues({})
  }

  useEffect(() => {
    console.log("filter value:", filterValues)
    console.log("filter value price:", filterValues["Price"])

    //   products.filter((product) =>
    //   product.category.toLowerCase().includes(filter.toLowerCase())
    // )
    data.filter(ads => ads.ca)
  }, [filterValues])
  //end

  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })

  const id = state.user.user.id
  //const userToken = state.token
  
  const  userToken = JSON.parse(localStorage.getItem("token"))
  
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  
  const homeLink = id ? "/admin" : "/"

  const searchKeyword = e => {
    setKeyword(e.target.value.trim())
  }

  function transformArray(arr) {
    return arr.map(item => (typeof item === "object" && item !== null && "name" in item ? item.name : item))
  }
  // useEffect(async () => {
  //   //check if the user logins, or navigate to home /
  //   const result = await axios.get(`${AppContext.apiUrl}/api/ads`, config)

  //   const typeOfAds = props.wanted ? 1 : 0
  //   const origData = result.data.filter(ads => ads.type == typeOfAds)
  //   const convertData = origData.map(e => Object.values(e))
  //   const convertData1 = convertData.map(e => transformArray(e).slice(0, 11))
  //   setData(convertData1)
  // }, [])

  useEffect(async () => {
    //check if the user logins, or navigate to home /
    const result = await axios.get(`${AppContext.apiUrl}/api/admin/ads`,config)

    const typeOfAds = props.wanted ? 1 : 0
    const origData = result.data.filter(ads => ads.type == typeOfAds)
    const convertData = origData.map(e => Object.values(e))
    const convertData1 = convertData.map(e => transformArray(e).slice(0, 12))
    setData(convertData1)
  }, [props.wanted])

  const search = e => {
    e.preventDefault()

    // axios
    //   .get(`http://localhost:8081/api/ads/search/${keyword}`)
    //   .then(response => {
    //     setData(response.data)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
  }

  const all = e => {
    e.preventDefault()

    axios
      .get("http://localhost:8081/api/ads")
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const car = e => {
    e.preventDefault()

    axios
      .get("http://localhost:8081/api/category/byName/car")
      .then(response => {
        setData(response.data[0].ads)
      })
      .catch(error => {
        console.log(error)
      })

    console.log(data)
  }

  const buying = e => {
    e.preventDefault()

    axios
      .get("http://localhost:8081/api/type/byName/buying")
      .then(response => {
        setData(response.data[0].ads)
      })
      .catch(error => {
        console.log(error)
      })

    console.log(data)
  }

  const selling = e => {
    e.preventDefault()

    axios
      .get("http://localhost:8081/api/type/byName/selling")
      .then(response => {
        setData(response.data[0].ads)
      })
      .catch(error => {
        console.log(error)
      })

    console.log(data)
  }

  const realEstate = e => {
    e.preventDefault()

    axios
      .get("http://localhost:8081/api/category/byName/real estate")
      .then(response => {
        setData(response.data[0].ads)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const goToMake = e => {
    e.preventDefault()
    navigate("/admin/make")
  }
  const devices = e => {
    e.preventDefault()

    axios
      .get("http://localhost:8081/api/category/byName/devices")
      .then(response => {
        setData(response.data[0].ads)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const logout = e => {
    const action = removeUser()
    dispatch(action)
    navigate("/")
  }

  {
    /* test start */
  }

  const [ageFilterChecked, setAgeFilterChecked] = useState(false)
  const columns = [
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRenderLite: v => {
          //alert(data[v])
          let imageurl = data[v] ? data[v][7] : ""
          //return <Avatar variant="rounded" src="https://res.cloudinary.com/elmelm/image/upload/v1706799276/adsimage/x0vbqpysjrdnpwwxok2w.jpg"></Avatar>
          return <Avatar variant="rounded" src={imageurl ? imageurl.split("$")[0] : ""}></Avatar>
        },
        filter: false
      }
    },
    {
      name: "listing#",
      label: "Listing#",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: false,
        sort: false
      }
    },
    {
      name: "make",
      label: "Manufacturer",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[3]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                {/* <InputLabel htmlFor="select-multiple-chip"></InputLabel> */}
                <Autocomplete
                  multiple
                  id="makes-autocomplete"
                  options={optionValues}
                  value={filterList[index]}
                  onChange={(event, newValue) => {
                    filterList[index] = newValue
                    onChange(filterList[index], index, column)
                  }}
                  renderInput={params => <TextField {...params} label="Manufacturer" />}
                />
              </FormControl>
            )
          }
        }
      }
    },

    {
      name: "model",
      label: "Model",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[4]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Model</InputLabel>
                <Autocomplete
                  multiple
                  id="model-autocomplete"
                  options={optionValues}
                  value={filterList[index]}
                  onChange={(event, newValue) => {
                    filterList[index] = newValue
                    onChange(filterList[index], index, column)
                  }}
                  renderInput={params => <TextField {...params} label="Model" variant="standard" />}
                />
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[5]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Category</InputLabel>
                <Autocomplete
                  multiple
                  id="contact-autocomplete"
                  options={optionValues}
                  value={filterList[index]}
                  onChange={(event, newValue) => {
                    filterList[index] = newValue
                    onChange(filterList[index], index, column)
                  }}
                  renderInput={params => <TextField {...params} label="Category" variant="standard" />}
                />
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "waferSize",
      label: "WaferSize",
      options: {
        filter: true,
        sort: false
      }
    },
    {
      name: "image1",
      label: "Image1",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "company",
      label: "Company",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            // const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo"]
            const optionValues = data.map(d => d[8]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Company</InputLabel>
                <Autocomplete
                  multiple
                  id="company-autocomplete"
                  options={optionValues}
                  value={filterList[index]}
                  onChange={(event, newValue) => {
                    filterList[index] = newValue
                    onChange(filterList[index], index, column)
                  }}
                  renderInput={params => <TextField {...params} label="Company" variant="standard" />}
                />
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "contact",
      label: "Contact",
      options: {
        filter: true,
        display: "true",
        filterType: "custom",
        customFilterListOptions: {
          render: v => v.map(l => l.toUpperCase()),
          update: (filterList, filterPos, index) => {
            console.log("update")
            console.log(filterList, filterPos, index)
            filterList[index].splice(filterPos, 1)
            return filterList
          }
        },
        filterOptions: {
          logic: (location, filters, row) => {
            if (filters.length) return !filters.includes(location)
            return false
          },
          display: (filterList, onChange, index, column) => {
            //get all unique makes
            //const optionValues = ["Minneapolis", "New York", "Seattle", "Some", "zoo", "Ninneapolis", "Sew York", "Weattle", "Eome", "Soo", "aaa", "bbb", "ccc", "eee"]
            const optionValues = data.map(d => d[9]).filter((value, index, array) => array.indexOf(value) === index)

            return (
              <FormControl>
                <InputLabel htmlFor="select-multiple-chip">Contact</InputLabel>
                <Autocomplete
                  multiple
                  id="contact-autocomplete"
                  options={optionValues}
                  value={filterList[index]}
                  onChange={(event, newValue) => {
                    filterList[index] = newValue
                    onChange(filterList[index], index, column)
                  }}
                  renderInput={params => <TextField {...params} label="Contact" variant="standard" />}
                />
              </FormControl>
            )
          }
        }
      }
    },
    {
      name: "Date added",
      label: "Date added",
      options: {
        sort: true,
        filter: true,
        filterType: "custom",

        // if the below value is set, these values will be used every time the table is rendered.
        // it's best to let the table internally manage the filterList
        //filterList: [25, 50],

        customFilterListOptions: {
          render: v => {
            if (v[0] && v[1] && ageFilterChecked) {
              return [`Min Date: ${v[0]}`, `Max Date: ${v[1]}`]
            } else if (v[0] && v[1] && !ageFilterChecked) {
              return `Min Date: ${v[0]}, Max Date: ${v[1]}`
            } else if (v[0]) {
              return `Min Date: ${v[0]}`
            } else if (v[1]) {
              return `Max Date: ${v[1]}`
            }
            return []
          },
          update: (filterList, filterPos, index) => {
            console.log("customFilterListOnDelete: ", filterList, filterPos, index)

            if (filterPos === 0) {
              filterList[index].splice(filterPos, 1, "")
            } else if (filterPos === 1) {
              filterList[index].splice(filterPos, 1)
            } else if (filterPos === -1) {
              filterList[index] = []
            }

            return filterList
          }
        },
        filterOptions: {
          names: [],
          logic(age, filters) {
            const dateTmp = new Date(age).getTime()

            if (filters[0] && filters[1]) {
              return dateTmp <= new Date(filters[0]).getTime() || dateTmp >= new Date(filters[1]).getTime()
            } else if (filters[0]) {
              return dateTmp <= new Date(filters[0]).getTime()
            } else if (filters[1]) {
              return dateTmp >= new Date(filters[1]).getTime()
            }
            return false
          },
          display: (filterList, onChange, index, column) => (
            <div>
              <FormLabel>Date added</FormLabel>
              <FormGroup row>
                <TextField
                  type="date"
                  label="min"
                  value={filterList[index][0] || ""}
                  onChange={event => {
                    filterList[index][0] = event.target.value
                    onChange(filterList[index], index, column)
                  }}
                  style={{ width: "45%", marginRight: "5%" }}
                />
                <TextField
                  type="date"
                  label="max"
                  value={filterList[index][1] || ""}
                  onChange={event => {
                    filterList[index][1] = event.target.value
                    onChange(filterList[index], index, column)
                  }}
                  style={{ width: "45%" }}
                />
              </FormGroup>
            </div>
          )
        },
        print: false
      }
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: false
      }
    }
  ]

  const data1 = [
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"],
    ["Joe James", "Test Corp", "Yonkers", "NY"],
    ["John Walsh", "Test Corp", "Hartford", "CT"],
    ["Bob Herm", "Test Corp", "Tampa", "FL"],
    ["James Houston", "Test Corp", "Dallas", "TX"]
  ]

  const options = {
    filterType: "checkbox",
    filter: true,
    responsive: "scroll",
    download: false,
    sort: true,
    selectableRows: false,
    print: false,
    viewColumns: false,
    searchOpen: false,
    searchText: keyword,
    search: false,
    rowHover: true,
    responsive: "vertical",
    onRowClick: (rowData, rowMeta) => {
      console.log(rowData)
      console.log(rowMeta)
      console.log(rowMeta.dataIndex)

      console.log(data[rowMeta.dataIndex][0])
      let _id = data[rowMeta.dataIndex][0]
      // window.open(`/editCard/${_id}`)
      //encode the id
      navigate(`/editCard/${_id}`)
      // this.props.history.push({
      //   pathname: `/ads/${_id}`,
      //   state: { issue: data[rowMeta.rowIndex] }
      // })
    }
  }

  {
    /* test end */
  }

  return (
    <div>
      {console.log(data)}
      <Navbar />

      <div className="search">
        <div className="category-nav">{props.wanted ? "Wanted list" : "For Sale list"}</div>
        <form className="example">
          <input type="text" placeholder="..something in mind? " onChange={searchKeyword} />
          <button>
            <i className="bi bi-search" onClick={search}></i>
          </button>
        </form>
      </div>
      <div className="category-nav">
        {/* <ul>
          <button className="btn btn-secondary home-button">
            <i onClick={all}>All</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={buying}>buying</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={selling}>selling</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={car}>front end equipments</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={realEstate}>backend equipments</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={devices}>Devices</i>
          </button>
        </ul> */}
      </div>

      {/* <LeftSidebarFilter />
        <ProductTable /> */}
      {/* <Box sx={{ bgcolor: "#F5F5F5", width: "300px", p: "24px", marginLeft: "20px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box fontSize="h6.fontSize" fontWeight="bold">
              Filters
            </Box>
            <Button variant="text" color="primary" onClick={handleResetAll}>
              Reset All
            </Button>
          </Box>
          <List>
            {filters.map((filter, index) => (
              <React.Fragment key={filter.label}>
                <ListItem button onClick={() => handleToggleFilter(filter.label)}>
                  <ListItemText primary={filter.label} />
                  {openFilters[filter.label] ? <Remove sx={{ color: filter.required ? "red" : undefined }} /> : <Add sx={{ color: filter.required ? "red" : undefined }} />}
                </ListItem>
                <Collapse in={openFilters[filter.label]} timeout="auto" unmountOnExit>
                  {filter.type === "autocomplete" && (
                    <ListItem sx={{ pl: 4 }}>
                      <Autocomplete
                        options={filter.options}
                        renderInput={params => <TextField {...params} label={filter.label} fullWidth />}
                        onChange={(event, value) => handleAutocompleteChange(event, value, filter.label)}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            border: "none"
                          }
                        }}
                      />
                    </ListItem>
                  )}
                  {filter.type === "select" && (
                    <ListItem sx={{ pl: 4 }}>
                      <FormControl fullWidth>
                        <InputLabel>{filter.label}</InputLabel>
                        <Select label={filter.label} name={filter.label} value={filterValues[filter.label] || ""} onChange={handleFilterChange}>
                          {filter.options.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ListItem>
                  )}
                  {filter.type === "checkbox" && (
                    <List component="div" disablePadding>
                      {filter.options.map(option => (
                        <ListItem key={option} button sx={{ pl: 4 }}>
                          <Checkbox
                            checked={(Array.isArray(filterValues[filter.label]) && filterValues[filter.label].includes(option)) || false}
                            onChange={handleCheckboxChange}
                            name={filter.label}
                            value={option}
                            sx={{
                              "&.Mui-checked": {
                                color: "red"
                              }
                            }}
                          />
                          <ListItemText primary={option} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Collapse>
                {index < filters.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Box> */}

      <div className="all-ads">
        {/* test start */}

        <MUIDataTable title={""} data={data} columns={columns} options={options} />
        {/* test end */}
      </div>
    </div>
  )
}

export default Home
