import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"

// const pipelineStatus = [{id:12,}"Market", "Finance", "Development"]
// const randomRole = () => {
//   return randomArrayItem(roles)
// }

// const initialRows = [
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 25,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 36,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 19,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 28,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   },
//   {
//     id: randomId(),
//     name: randomTraderName(),
//     age: 23,
//     joinDate: randomCreatedDate(),
//     role: randomRole()
//   }
// ]

function EditToolbar(props) {
  const navigate = useNavigate()
  const { setRows, setRowModesModel } = props

  const handleClick = () => {
    // const id = Math.abs(randomInt())
    // setRows(oldRows => [...oldRows, { id, name: "", isNew: true }])
    // setRowModesModel(oldModel => ({
    //   ...oldModel,
    //   [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" }
    // }))
    navigate("/admin/AddPipeline")
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" onClick={handleClick}>
        Add new Pipeline
      </Button>
    </GridToolbarContainer>
  )
}

export default function PipelinesList(props) {
  const navigate = useNavigate()
  const [rows, setRows] = React.useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})
  const [modelList, setModelList] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })
  const CurrencyEditor = ({ value, onValueChange }) => {
    const handleInputChange = event => {
      // Assuming the input is a valid currency format (e.g., "$1,000.00")
      onValueChange(event.target.value)
    }

    return <input type="text" value={value} onChange={handleInputChange} />
  }

  function convertAllDatesToYyyyMmDd(allData) {
    return allData.map(item => {
        return {
            ...item,
            createdAt: new Date(item.createdAt).toISOString().split('T')[0]
        };
    });
}

  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/pipeline`, config)
    const converted = convertAllDatesToYyyyMmDd(result.data)
    const filteredData = converted
      .filter(item => item.status !== '0% - no Deal')
      .filter(item => item.status !== '100% - Deal')
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const filteredStatusData = converted
      .filter(item => item.status === '0% - no Deal')
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const filteredStatus100Data = converted
      .filter(item => item.status === '100% - Deal')
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    if(props.status==="0"){
      setRows(filteredStatusData)
    }else if(props.status==="100"){
      setRows(filteredStatus100Data)
    }else{
      setRows(filteredData)
    }
  }, [refresh,props.status])
  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }
  //const pipelines = [{ id: "122222", status: "10%", date_created: "2024-02-02" }]
  //call api to get the pipelines list on data load
  React.useEffect(async () => {
    let result = await axios.get(`${AppContext.apiUrl}/api/pipeline`, config)
    const converted = convertAllDatesToYyyyMmDd(result.data)
    const filteredData = converted
      .filter(item => item.status !== '0% - no Deal')
      .filter(item => item.status !== '100% - Deal')
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const filteredStatusData = converted
      .filter(item => item.status === '0% - no Deal')
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      const filteredStatus100Data = converted
      .filter(item => item.status === '100% - Deal')
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    if(props.status==="0"){
      setRows(filteredStatusData)
    }else if(props.status==="100"){
      setRows(filteredStatus100Data)
    }else{
      setRows(filteredData)
    }
  }, [])

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = id => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = id => () => {
    setRows(rows.filter(row => row.id !== id))
  }

  const handleCancelClick = id => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true }
    })

    const editedRow = rows.find(row => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter(row => row.id !== id))
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map(row => (row.id === newRow.id ? updatedRow : row)))
    console.log(newRow.id, newRow.name, updatedRow.isNew)

    if (newRow.isNew) {
      axios
        .post(`${AppContext.apiUrl}/api/pipeline`, { id: newRow.id, status: newRow.status, logisticsDeductions: newRow.logisticsDeductions, nextSteps: newRow.nextSteps, equipmentName: newRow.equipmentName, buyerRep: newRow.buyerRep, buyerCompany: newRow.buyerCompany, buyerContact: newRow.buyerContact, sellerRep: newRow.sellerRep, sellerCompany: newRow.sellerCompany, sellerContact: newRow.sellerContact, buyPrice: newRow.buyPrice, sellPrice: newRow.sellPrice, logisticsPrice: newRow.logisticsPrice, quantity: newRow.quantity, note: newRow.note, reasonLost: newRow.reasonLost }, config)
        .then(
          setTimeout(function () {
            // Something you want delayed.
            setRefresh(true)
          }, 3000)
        )
        .catch(e => {
          console.log("something happend when editing makes")
        })
    } else {
      axios
        .put(`${AppContext.apiUrl}/api/pipeline/${newRow.id}`, { id: newRow.id, status: newRow.status,created:newRow.createdAt, nextSteps: newRow.nextSteps, logisticsDeductions: newRow.logisticsDeductions, equipmentName: newRow.equipmentName, buyerRep: newRow.buyerRep, buyerCompany: newRow.buyerCompany, buyerContact: newRow.buyerContact, sellerRep: newRow.sellerRep, sellerCompany: newRow.sellerCompany, sellerContact: newRow.sellerContact, buyPrice: newRow.buyPrice, logisticsPrice: newRow.logisticsPrice, quantity: newRow.quantity, note: newRow.note, reasonLost: newRow.reasonLost, sellPrice: newRow.sellPrice }, config)
        .then(
          setTimeout(function () {
            // Something you want delayed.
            setRefresh(!refresh)
          }, 1000)
        )
        .catch(e => {
          console.log("something happend when editing makes")
        })
    }
    return updatedRow
  }

const goToDetails = rowSelected =>{
  //alert(rowSelected.id)
  localStorage.setItem("selectedPipelineDetailId",rowSelected.id)

  navigate(`/admin/pipelineDetail/${rowSelected.id}`)
}

  const handleRowModesModelChange = newRowModesModel => {
    setRowModesModel(newRowModesModel)
  }
  function formatCurrency(e) {
    if (!e) return e
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(e)
  }
  const fieldEditable = false
  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 80,
      editable: true,
      type: "singleSelect",
      renderCell: v => {
        return v.value ?v.value.split(' ')[0]  : ""
      },
      valueOptions: ["10% - One Sided Deal", "25% Confirmed Buyer and Seller", "50% Checking Price and Config", "75% Price Config confirmed", "90% Contract"]
      // renderCell: params => (
      //   <select
      //     style={{ width: "400px" }}
      //     //value={params.value[0] ? params.value[0].id : ""} // Assuming params.value contains the selected model
      //     value={{ id: params.row.model && params.row.model.length > 0 ? params.row.model[0].id : "", name: params.row.model && params.row.model.length > 0 ? params.row.model[0].name : "" }} // Assuming params.value contains the selected model
      //     onChange={e => {
      //       // Handle the model change here
      //       const newValue = e.target.value
      //       console.log("------params---" + params)
      //       // You may want to call an update function to update your data
      //       // For example: updateModel(params.id, newValue)
      //     }}
      //   >
      //     {pipelineStatus.map(modelOption => (
      //       <option key={modelOption ? modelOption.id : ""} id={modelOption ? modelOption.id : ""} value={modelOption ? modelOption.name : ""}>
      //         {modelOption ? modelOption.name : ""}
      //       </option>
      //     ))}
      //   </select>
      // )
    },
    { field: "createdAt", headerName: "Created Date", width: 100, editable: false },
    { field: "nextSteps", headerName: "Next Steps", width: 200, editable: true },
    { field: "equipmentName", headerName: "Equipment Name", width: 200, editable: true },
    { field: "buyerRep", headerName: "Buyer Rep", width: 200, editable: true },
    { field: "buyerCompany", headerName: "Buyer Company", width: 200, editable: true },
    { field: "buyerContact", headerName: "Buyer Contact", width: 200, editable: true },
    { field: "sellerRep", headerName: "Seller Rep", width: 200, editable: true },
    { field: "sellerCompany", headerName: "Seller Company", width: 200, editable: true },
    { field: "sellerContact", headerName: "Seller Contact", width: 200, editable: true },
    {
      field: "buyPrice",
      headerName: "Buy Price",
      width: 150,
      editable: false
      // renderCell: params => (
      //   <strong>
      //     {new Intl.NumberFormat("en-US", {
      //       style: "currency",
      //       currency: "USD"
      //     }).format(params.value)}
      //   </strong>
      // ),
      // renderEditCell: params => <CurrencyEditor value={params.value} onValueChange={newValue => params.api.setEditCellValue(params.id, params.field, newValue)} />

      // valueGetter: params => {
      //   // Convert the decimal value to a percentage

      //   return formatCurrency(params.value)
      // }
      // ,
      // valueSetter: params => {
      //   return params.value ? params.val.replace("$", "").replace(",", "") : ""
      // }
    },
    { field: "sellPrice", headerName: "Sell Price", width: 150, editable: false },
    { field: "logisticsDeductions", headerName: "Logistics Deductions", width: 200, editable: true },
    { field: "logisticsPrice", headerName: "Logistics Price", width: 200, editable: true },
    { field: "quantity", headerName: "Quantity", width: 70, editable: true },
    {
      field: "spread",
      headerName: "Profit",
      width: 100,
      editable: false
      // valueGetter: ({ row }) => {
      //   if (!row.sellPrice || !row.buyPrice) {
      //     return null
      //   }
      //   return (row.sellPrice - row.buyPrice) * row.quantity - row.logisticsDeductions
      // }
    },
    { field: "note", headerName: "Note", width: 200, editable: true },
    { field: "reasonLost", headerName: "Reason Lost", width: 200, editable: true },

    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main"
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem icon={<CancelIcon />} label="Cancel" className="textPrimary" onClick={handleCancelClick(id)} color="inherit" />
          ]
        }

        return [
          <GridActionsCellItem icon={<EditIcon />} label="Edit" className="textPrimary" onClick={handleEditClick(id)} color="inherit" />
          //<GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={handleDeleteClick(id)} color="inherit" />
        ]
      }
    }
  ]

  return (
    <Box
      sx={{
        display: 'inline-block',
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        //editMode="cell"
        rowModesModel={rowModesModel}
        onRowClick={goToDetails}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel }
        }}
      />
    </Box>
  )
}
