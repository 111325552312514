// import { Height } from "@mui/icons-material"
// import React, { useState } from "react"
// import { useDropzone } from "react-dropzone"
// const UploadFile = () => {
//   const [uploadedFiles, setUploadedFiles] = useState([])
//   const { getRootProps, getInputProps } = useDropzone({
//     onDrop: acceptedFiles => {
//       setUploadedFiles(acceptedFiles)
//       // Call your backend API endpoint to upload files
//     }
//   })
//   return (
//     <div {...getRootProps()}>
//       <input {...getInputProps()} />
//       <p
//         style={{
//           border: "1px solid #ccc", // Add a thin border
//           padding: "5px", // Optional: Add some padding for better appearance,
//           height: "300px",
//           fontSize: "1"
//         }}
//       >
//         Drag and drop files here or click to browse.
//         <ul>
//           {uploadedFiles.map(file => (
//             <li key={file.name}>{file.name}</li>
//           ))}
//         </ul>
//       </p>
//     </div>
//   )
// }
// export default UploadFile
