import * as React from "react"
import {TextField,Box} from "@mui/material"
import Button from "@mui/material/Button"
import AddIcon from "@mui/icons-material/Add"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import SaveIcon from "@mui/icons-material/Save"
import CancelIcon from "@mui/icons-material/Close"
import { GridRowModes, DataGrid, GridToolbarContainer, GridActionsCellItem, GridRowEditStopReasons } from "@mui/x-data-grid"
import { randomCreatedDate, randomTraderName, randomId, randomArrayItem } from "@mui/x-data-grid-generator"
import { Margin } from "@mui/icons-material"
import axios from "axios"
import AppContext from "../../AppContext"
import Models from "../model/Models"
import { FormControl } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { encodeNumberTo10CharString } from "../../NumberCodec"
import { useState } from "react"

const roles = ["Market", "Finance", "Development"]
const randomRole = () => {
  return randomArrayItem(roles)
}


export default function ContactEmailListGlobalSearch(props) {
  const [rows, setRows] = React.useState([])
  const [rowModesModel, setRowModesModel] = React.useState({})

  const [refresh,setRefresh] =useState(false)
  const navigate = useNavigate()


  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }
  function isValidEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}
  // const goToDetails = rowSelected =>{
  //   alert(rowSelected.date)
  //   alert(rowSelected.body)

  //   //localStorage.setItem("selectedPipelineDetailId",rowSelected.id)
  
  //   localStorage.setItem("emailDate",rowSelected.date)
  //   localStorage.setItem("emailBody",rowSelected.body)
  //   navigate(`/emailDetails`)
  // }

  const [selectionModel, setSelectionModel] = useState([]);

  // Handle row selection change
  const handleSelectionModelChange = (newSelection) => {
    setSelectionModel(newSelection);
    // Retrieve data for the selected rows
    const selectedRowData = rows.filter(row => newSelection.id ===row.id);
    console.log('Selected Row Data:', selectedRowData);
    localStorage.setItem("emailDate",selectedRowData[0].date)
    localStorage.setItem("emailId",selectedRowData[0].id)
    // navigate(`/emailDetails`,"_blank")
    const url = '/emailDetails';
    // Open a new window or tab with the specified URL
    window.open(url, '_blank');
  };
  

  function arrayToObject(arr) {
    return {
      id: arr.messageId,
      date:arr.date,
     
      message:arr.snippet,
      body:arr.body,
      contactName:arr.contactName,
      contactCompanyName:arr.contactCompanyName
    }
  }


  //call api to get the make list on data load
  React.useEffect( () => {
    if(isValidEmail(props.contactEmail)){
    let result =  axios.get(`${AppContext.apiUrl}/api/admin/gmailtool/${props.contactEmail}`, config).then(rs=>{
      let temp = rs.data.map(arrayToObject)
      //console.log(temp)
      setRows(temp)
    })
  }else if(props.contactEmail){
    let result =  axios.get(`${AppContext.apiUrl}/api/admin/gmailtool/globalSearch/${props.contactEmail}`, config).then(rs=>{
      let temp = rs.data.map(arrayToObject)
      //console.log(temp)
      setRows(temp)
    })
  }

    setRefresh(props.refresh)

    // setRows(result.data.map(arrayToObject))
  }, [])

  function isValidEmail(email) {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  React.useEffect( () => {
    if(isValidEmail(props.contactEmail)){
      let result =  axios.get(`${AppContext.apiUrl}/api/admin/gmailtool/${props.contactEmail}`, config).then(rs=>{
        let temp = rs.data.map(arrayToObject)
        //console.log(temp)
        setRows(temp)
      })
    }else{
      let result =  axios.get(`${AppContext.apiUrl}/api/admin/gmailtool/globalSearch/${props.contactEmail}`, config).then(rs=>{
        let temp = rs.data.map(arrayToObject)
        //console.log(temp)
        setRows(temp)
      })
    }

    // setRows(result.data.map(arrayToObject))
  }, [props.contactEmail])

  const [filters, setFilters] = useState({
    id: '',
    date: '',
    message:''
  });

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value
    }));
  };

  const filteredRows = rows.filter((row) =>
    Object.keys(filters).every((field) =>
      String(row[field]).toLowerCase().includes(filters[field].toLowerCase())
    )
  );
  
  const columns = [
    // { field: "id", headerName: "Id", width: 170},
    { field: "date", headerName: "date", width: 170},
    { field: "contactName", headerName: "Contact Name", width: 170},
    { field: "contactCompanyName", headerName: "Contact Company Name", width: 170},
    { field: "message", headerName: "message", width: 1000},

  ]

  
  
  return (
    <Box
      sx={{
        height: 500,
        width: "100%",
        "& .actions": {
          color: "text.secondary"
        },
        "& .textPrimary": {
          color: "text.primary"
        }
      }}
    >
            
      {rows && (
      <DataGrid
        rows={rows}
        columns={columns}
        //getRowId={(row) => row.id}
        // editMode="cell"
        // getRowId={(row) => row.id}
        // onRowClick={(e)=>goToDetails(e)}
        onRowClick={handleSelectionModelChange}
        selectionModel={selectionModel}
        // rowModesModel={rowModesModel}
        // onRowModesModelChange={handleRowModesModelChange}
        // onRowEditStop={handleRowEditStop}
        // processRowUpdate={processRowUpdate}
        // slots={{
        //   toolbar: EditToolbar
        // }}
        // slotProps={{
        //   toolbar: { setRows, setRowModesModel }
        // }}
      />)}
      {!rows && (
      <p>Loading...</p>
      )}
    </Box>
  )
}
