import React, { useEffect, useState } from "react"
import Select from "react-select"
import axios from "axios"
import { useSelector } from "react-redux"
import { encodeNumberTo10CharString } from "../../NumberCodec"
import AppContext from "../../AppContext"

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" }
// ]

function CompanyContactList({ options, selectedValue, onSelectChange }) {
  //const [options, setOptions] = useState()
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const handleSelectChange = event => {
    const value = event
    onSelectChange(value)
  }
  const goToContactUrl= (id)=>{
    window.open(AppContext.adminUrl+"contact/"+encodeNumberTo10CharString(id),'_blank')
  }
  return (
  <><Select options={options} value={selectedValue} onChange={handleSelectChange} />
 <a onClick={()=>goToContactUrl(selectedValue.value)} style={{textDecoration:"underLine",cursor:"pointer"}} >Go to Contact details</a>
</>
  )
}

export default CompanyContactList
