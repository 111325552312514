import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import CompanyList from "../company/CompanyList"
import Makes from "../productMakes/Makes"
import Models from "../model/Models"
import CompanyContactList from "../companyContact/CompanyContactList"
import Select from "react-select"
import countryList from "react-select-country-list"
import AppContext from "../../AppContext"
import Category from "../category/Category"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import FileUpload from "../FileUpload"
import InternalFileUpload from "../InternalFileUpload"
import ExternalFileUpload from "../ExternalFileUpload"
import { encodeNumberTo10CharString,decodeStringToNumber } from "../../NumberCodec"
import { useScrollTrigger } from "@mui/material"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"
import { Button } from "react-bootstrap"


function PipeLineDetails(props) {
  const params = useParams() //params.adsId
  const [data, setData] = useState()
  const [adsTitle, setAdsTitle] = useState()
  const [country, setCountry] = useState()
  const [price, setPrice] = useState(0)
  const [adsLocation, setadsLocation] = useState()
  const [adsDescription, setadsDescription] = useState()
  const [adsImage, setadsImage] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [makesOptions, setMakesOptions] = useState()
  const [makesList, setMakesList] = useState()
  const [modelsOptions, setModelsOptions] = useState()
  const [category, setCategory] = useState()
  const [categoryName, setCategoryName] = useState()
  const [contactsOptions, setContactsOptions] = useState()
  const [companyOptions, setCompanyOptions] = useState()
  const [companyList, setCompanyList] = useState()
  const [waferSizeOptions, setWaferSizeOptions] = useState()
  const [selectedWaferSizeValue, setSelectedWaferSizeValue] = useState()
  const [configuration, setConfiguration] = useState()
  const [note, setNote] = useState()
  const [vintage, setVintage] = useState()
  const [serialNumber, setSerialNumber] = useState()
  const [hideFromFrontEnd, setHideFromFrontEnd] = useState()
  const [imagesUrls, setImagesUrls] = useState([])
  const [featuredItem, setFeaturedItem] = useState(false)

  const [internalFilesUrls, setInternalFilesUrls] = useState([])
  const [externalFilesUrls, setExternalFilesUrls] = useState([])

  const [state_, setState_] = useState()
  const [city, setCity] = useState()
  const [quantity, setQuantity] = useState()
  const [process, setProcess] = useState()
  const [availableDate, setAvailableDate] = useState()
  const [selectedPipelineDetailId,setSelectedPipelineDetailId]= useState()

  //real data needed on details
  const [buyerCompany,setBuyerCompany] = useState()
  const [buyerCompanyId,setBuyerCompanyId] = useState()
  const [buyerContact,setBuyerContact] = useState()
  const [buyerContactId,setBuyerContactId] = useState()

  const [sellerCompany,setSellerCompany] = useState()
  const [sellerCompanyId,setSellerCompanyId] = useState()
  const [sellerContact,setSellerContact] = useState()
  const [sellerContactId,setSellerContactId] = useState()

  const [matchList,setMatchList] = useState()

  const [makeModel,setMakeModel] = useState()
  //logisticsDeductions logisticsPrice otherCost
  const [logisticsDeductions,setLogisticsDeductions] = useState()
  const [logisticsPrice,setLogisticsPrice] = useState()
  const [otherCost,setOtherCost] = useState()
  const [matchSubtotal,setMatchSubtotal] = useState()
  const [selectedStatusValue,setSelectedStatusValue]=useState()
  const [nextSteps,setNextSteps] =useState()
  const [equipmentName,setEquipmentName] =useState()
  const [buyerRep,setBuyerRep] =useState()
  const [sellerRep,setSellerRep] =useState()
  const [open, setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  const [reasonLost,setReasonLost] =useState()
  const [selectedReasonValue,setSelectedReasonValue] = useState()
//save price on the page
  // Initialize state for all items in the matchList
  // Initialize state for all items in the matchList
  const [priceList, setPriceList] = useState([]);

  function formatCurrency(e) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(e)
  }

  const resetStyle = {
    backgroundColor: 'transparent', // Revert any background color changes
    border: '2px inset', // Default style for many browsers, but not all
    fontFamily: 'initial', // Reverts to the default font
    fontSize: 'medium', // Default size
    color: 'initial', // Reverts to the default text color
    padding: 'initial', // Removes any padding
    margin: 'initial', // Removes any margin
  };

  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  const statusOption =[
    {"value":"0% - no Deal","label":"0% - no Deal"},
    {"value":"10% - One Sided Deal","label":"10% - One Sided Deal"},
    {"value":"25% Confirmed Buyer and Seller","label":"25% Confirmed Buyer and Seller"},
    {"value":"50% Checking Price and Config","label":"50% Checking Price and Config"},
    {"value":"90% Contract","label":"90% Contract"},
    {"value":"100% - Deal","label":"100% - Deal"}
     ]
  function calculateSums(dataTemp) {
    let sumObject1 = 0;
    let sumObject2 = 0;

    dataTemp && dataTemp.forEach(item => {
        if (item.wantedAds && item.wantedAds.price) {
            sumObject1 += item.wantedAds.price;
        }
        if (item.forSaleAds && item.forSaleAds.price) {
            sumObject2 += item.forSaleAds.price;
        }
    });

    return sumObject1 - sumObject2 
}

const reasonOption =[
  {"value":"Price not Matched","label":"Price not Matched"},
  {"value":"Out-bided","label":"Out-bided"},
  {"value":"Technical Not Matched","label":"Technical Not Matched"},
  {"value":"Project Discontinued","label":"Project Discontinued"},
  {"value":"Duplicate Pipeline","label":"Duplicate Pipeline"}
   ]

  useEffect(async () => {
    
    setSelectedPipelineDetailId(JSON.parse(localStorage.getItem("token")))

    const result = await axios.get(`${AppContext.apiUrl}/api/pipeline/${params.pipelineId}`,config).then(res=>{
      setData(res.data)
      
      setLogisticsDeductions(res.data.logisticsDeductions)
      setLogisticsPrice(res.data.logisticsPrice)
      setOtherCost(res.data.otherCost)
      setInternalFilesUrls(res.data.internalFiles ? res.data.internalFiles.split("$") : null)
      setSelectedStatusValue({value:res.data.status,label:res.data.status})
      setNextSteps(res.data.nextSteps)
      setEquipmentName(res.data.equipmentName)
      setBuyerRep(res.data.buyerRep)
      setSellerRep(res.data.sellerRep)
      setNote(res.data.note)
      setSelectedReasonValue({value:res.data.reasonLost,label:res.data.reasonLost})
    })
    
    // setCategory(result.data.category)
    // setCategoryName(result.data.category.name)

    
  }, [])

  useEffect(async () => {
    

    await axios.get(`${AppContext.apiUrl}/api/pipeline/transactionpair/${params.pipelineId}`, config).then(
      res=>{setMatchList(res.data)
      setBuyerCompany(res.data[0].wantedAds.company.name)
      setBuyerCompanyId(res.data[0].wantedAds.company.id)
      setBuyerContact(res.data[0].wantedAds.contact.firstName)
      setBuyerContactId(res.data[0].wantedAds.contact.id)

      setSellerCompany(res.data[0].forSaleAds.company.name)
      setSellerCompanyId(res.data[0].forSaleAds.company.id)
      setSellerContact(res.data[0].forSaleAds.contact.firstName)
      setSellerContactId(res.data[0].forSaleAds.contact.id)
      //setMatchList(res.data.transactionPairs)
      setMakeModel(res.data[0].forSaleAds.make.name+" / "+res.data[0].forSaleAds.model.name)
      if (matchList && matchList.length > 0) {
        setPriceList(
          matchList.map((match) => ({
            forsalePrice: match.forSaleAds.price,
            wantedPrice: match.wantedAds.price,
          }))
        );
      }
      
      }
    )
    
    
    // setCategory(result.data.category)
    // setCategoryName(result.data.category.name)

    
  }, [])

  useEffect(()=>{
    setMatchSubtotal(calculateSums(matchList))
  },[matchList])

  const goToListingDetails = id =>{
    //alert(rowSelected.id)
    //e.preventDefault()
    window.open(`/editCard/${id}`)
    //navigate(`/editCard/${encodeNumberTo10CharString(id)}`)
  }

  const goToContactDetails = id =>{
    //alert(rowSelected.id)
    //e.preventDefault()
  
    //navigate(`/admin/contact/${encodeNumberTo10CharString(id)}`)
    window.open(`/admin/contact/${encodeNumberTo10CharString(id)}`)
  }

  const goToCompanyDetails = id =>{
    //alert(rowSelected.id)
    //e.preventDefault()
    
    //navigate(`/admin/company/${id}`)
    window.open(`/admin/company/${id}`)
  }

  const changeHandler = value => {
    setValue(value)
  }
  const token = JSON.parse(localStorage.getItem("token"))

  let file = false
  const [selectedCategoryValue, setSelectedCategoryValue] = useState("")

  const handleSelectCategoryChange = value => {
    setSelectedCategoryValue(value)
    console.log("category is ", value)
  }

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const companyId = 5
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const title = e => {
    setAdsTitle(e.target.value)
  }

  const location = e => {
    setadsLocation(e.target.value)
  }

  

  const uploadImage = async e => {
    const files = e.target.files
    const data = new FormData()
    data.append("file", files[0])
    data.append("upload_preset", "adsimage")
    setLoading(true)

    const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data).then(res => {
      file = true
      setImage(res.data.secure_url)
      setLoading(false)
    })
  }
  useEffect(() => {
    console.log("-----imagesUrls when editing ads---------------->>>")
    console.log(imagesUrls)
    //update image field
    //setImage(imagesUrls.join("$"))
  }, [imagesUrls])

  const saveDetails = e => {
    e.preventDefault()
    //call pipeline endpoint to save data
    //need to add internalFiles
    const data = {
      //id:params.pipelineId,
      internalFiles:internalFilesUrls?internalFilesUrls.join("$"):"",
      logisticsDeductions:logisticsDeductions,
      logisticsPrice:logisticsPrice,
      otherCost:otherCost,
      status:selectedStatusValue?selectedStatusValue.value:statusOption[0].value,
      nextSteps:nextSteps,
      equipmentName:equipmentName,
      buyerRep:buyerRep,
      sellerRep:sellerRep,
      transactionPairs:[],
      reasonLost:selectedReasonValue?selectedReasonValue.value:reasonOption[0].value,
      note:note
    }

    axios
        .put(`${AppContext.apiUrl}/api/pipeline/${params.pipelineId}`, data, config)
        .then((res)=>{
          setOpen(true)
        setSeverity("success")
        setMessage("Pipeline saved successfully.")
        }
        )
        .catch(e => {
          console.log("something happend when editing makes")
          setOpen(true)
          setSeverity("error")
        setMessage("Something went wrong, please contact the admin for help")
        })



  }
  
  const addNewMatch = (e)=>{
    e.preventDefault()
    navigate(`/admin/addWantedMatchForPipeline/${params.pipelineId}&${buyerContactId}&${sellerContactId}`)
  }

  

  // Ensure priceList is correctly initialized when matchList changes
  useEffect(() => {

    if (matchList && matchList.length > 0) {
      setPriceList(
        matchList.map((match) => ({
          forsalePrice: match.forSaleAds.price,
          wantedPrice: match.wantedAds.price,
        }))
      );
    }
  }, [matchList]);

  const handleSave = (index, match) => {
    // Save the current values for that specific item
    const { forsalePrice, wantedPrice } = priceList[index];
    save(index,match.forSaleAds.id, forsalePrice, match.wantedAds.id, wantedPrice);
    
  };

  const handleInputChange = (index, key, value) => {
    const newPriceList = [...priceList];
    newPriceList[index][key] = value;
    setPriceList(newPriceList); // Update state
  };

  const save = (index,forsaleId,forsalePrice,wantedId,wantedPrice) => {
    //call put on /ads to update the price
    const data ={
      price:forsalePrice,
      type:0,
      hideFromFrontEnd:0
    }
    axios.put(`${AppContext.apiUrl}/api/ads/${wantedId}`, {price:wantedPrice,type:1,
      hideFromFrontEnd:1}, config).then(res1=>{
        // Use the response data to update the UI
    const updatedMatchList = [...matchList];
    updatedMatchList[index].forSaleAds.price = res1.data.price; // Assuming the server returns the updated price
    setMatchList(updatedMatchList);
    
    // Update priceList accordingly
    const updatedPriceList = [...priceList];
    updatedPriceList[index].forsalePrice = res1.data.price;
    setPriceList(updatedPriceList);
      //refresh prices
      axios.get(`${AppContext.apiUrl}/api/pipeline/transactionpair/${params.pipelineId}`, config).then(
        res=>{setMatchList(res.data)
        setBuyerCompany(res.data[0].wantedAds.company.name)
        setBuyerCompanyId(res.data[0].wantedAds.company.id)
        setBuyerContact(res.data[0].wantedAds.contact.firstName)
        setBuyerContactId(res.data[0].wantedAds.contact.id)
  
        setSellerCompany(res.data[0].forSaleAds.company.name)
        setSellerCompanyId(res.data[0].forSaleAds.company.id)
        setSellerContact(res.data[0].forSaleAds.contact.firstName)
        setSellerContactId(res.data[0].forSaleAds.contact.id)
        //setMatchList(res.data.transactionPairs)
        setMakeModel(res.data[0].forSaleAds.make.name+" / "+res.data[0].forSaleAds.model.name)
        if (matchList && matchList.length > 0) {
          setPriceList(
            matchList.map((match) => ({
              forsalePrice: match.forSaleAds.price,
              wantedPrice: match.wantedAds.price,
            }))
          );
        }
        
        }
      )
    }
    )
    axios.put(`${AppContext.apiUrl}/api/ads/${forsaleId}`, data, config).then(res1=>{

      // Use the response data to update the UI
    const updatedMatchList = [...matchList];
    updatedMatchList[index].forSaleAds.price = res1.data.price; // Assuming the server returns the updated price
    setMatchList(updatedMatchList);
    
    // Update priceList accordingly
    const updatedPriceList = [...priceList];
    updatedPriceList[index].forsalePrice = res1.data.price;
    setPriceList(updatedPriceList);
      //refresh prices
      axios.get(`${AppContext.apiUrl}/api/pipeline/transactionpair/${params.pipelineId}`, config).then(
        res=>{setMatchList(res.data)
        setBuyerCompany(res.data[0].wantedAds.company.name)
        setBuyerCompanyId(res.data[0].wantedAds.company.id)
        setBuyerContact(res.data[0].wantedAds.contact.firstName)
        setBuyerContactId(res.data[0].wantedAds.contact.id)
  
        setSellerCompany(res.data[0].forSaleAds.company.name)
        setSellerCompanyId(res.data[0].forSaleAds.company.id)
        setSellerContact(res.data[0].forSaleAds.contact.firstName)
        setSellerContactId(res.data[0].forSaleAds.contact.id)
        //setMatchList(res.data.transactionPairs)
        setMakeModel(res.data[0].forSaleAds.make.name+" / "+res.data[0].forSaleAds.model.name)
        if (matchList && matchList.length > 0) {
          setPriceList(
            matchList.map((match) => ({
              forsalePrice: match.forSaleAds.price,
              wantedPrice: match.wantedAds.price,
            }))
          );
        }
        
        }
      )

      setOpen(true)
        setSeverity("success")
        setMessage("Price saved successfully.")
    }
    )
    
  }
 
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%'}}>
        {message}
        </Alert>
      </Snackbar>
      <Navbar />
      {data && (
        <form>
          <div className="ads-from">
          <div style={{textAlign:"center",fontWeight:"bolder"}}><h3>Pipeline details page</h3></div>
          <div style={{textAlign:"center",fontWeight:"bold"}}>{makeModel} </div>
          <div style={{textAlign:"center",fontWeight:"bold"}}>Buyer: <a style={{color:"blue",cursor:"pointer"}} onClick={()=>goToContactDetails(buyerContactId)}>{buyerContact}</a>&nbsp;&nbsp; <a style={{color:"blue",cursor:"pointer"}} onClick={()=>goToCompanyDetails(buyerCompanyId)}>{buyerCompany}</a> </div>
          <div style={{textAlign:"center",fontWeight:"bold"}}>Seller: <a style={{color:"blue",cursor:"pointer"}} onClick={()=>goToContactDetails(sellerContactId)}>{sellerContact} </a>&nbsp;&nbsp;<a style={{color:"blue",cursor:"pointer"}} onClick={()=>goToCompanyDetails(sellerCompanyId)}>{sellerCompany}</a></div>
          <hr/>
          <div><button onClick={addNewMatch}>Add new Match</button></div>
            <table style={{width:"98%",margin:"10px"}}>
            <tr style={{fontWeight:"bold"}}><td>Match #</td><td>Make/Model</td>
              <td>For sale ID</td><td>Wanted ID</td><td>Buy price</td>
              <td>Sell Price</td>
              <td>Profit</td> 
              </tr>
              {matchList && matchList.map((match,index)=>(
                <>
                <tr key={index}><td>{index+1}</td><td>{match.forSaleAds.make.name + "/" +match.forSaleAds.model.name} </td>
              <td><div style={{color:"blue",cursor:"pointer"}} onClick={()=>goToListingDetails(match.forSaleAds.id)}> {match.forSaleAds.id}</div></td><td><a style={{color:"blue",cursor:"pointer"}} onClick={()=>goToListingDetails(match.wantedAds.id)}>{match.wantedAds.id}</a></td>
              
              {/* For Sale Price Input */}
              <td>
                <input
                  type="text"
                  name="forsalePrice"
                  value={priceList&&priceList[index]&&priceList[index].forsalePrice} // Use state value
                  onChange={(e) =>
                    handleInputChange(index, 'forsalePrice', e.target.value)
                  } // Update state on change
                />
              </td>

              {/* Wanted Price Input */}
              <td>
                <input
                  type="text"
                  name="wantedPrice"
                  value={priceList&&priceList[index]&&priceList[index].wantedPrice} // Use state value
                  onChange={(e) =>
                    handleInputChange(index, 'wantedPrice', e.target.value)
                  } // Update state on change
                />
              </td>
              
              <td>${match.wantedAds.price-match.forSaleAds.price}</td>
              <td><button onClick={(e)=>{
                e.preventDefault()
                handleSave(index, match); 
                }}
                
                >Update this match</button></td>
              </tr>
              </>

              ))}
              <tr ><td>Subtotal</td><td></td>
              <td></td><td></td><td></td>
              <td></td>
              <td>${matchSubtotal}</td> 
              </tr>
              
              {/* <tr><td>2</td><td>Make/Modelsdfsdfds </td><td>8787878787</td><td>8989898</td><td>56660</td>
              <td>99999</td>
              <td>98998</td>
              </tr> */}
              <tr><td></td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td></td>
              </tr>
              <tr><td></td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td></td>
              </tr>
              <tr><td></td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td></td>
              </tr>
              <tr><td></td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td></td>
              </tr>
              <tr><td></td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td></td>
              </tr>
              <tr style={{marginTop:"10px"}}><td>Logistics Cost</td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td>$<input type="text" style={resetStyle} value={logisticsDeductions} onChange={evn=>{setLogisticsDeductions(evn.target.value)}}/></td>
              </tr>
              <tr style={{marginTop:"10px"}}><td>Other Cost</td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td>$<input type="text" style={resetStyle} value={otherCost} onChange={evn=>{setOtherCost(evn.target.value)}}/></td>
              </tr>
              <tr><td>Logistics Profit</td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td>$<input type="text" style={resetStyle} value={logisticsPrice} onChange={evn=>{setLogisticsPrice(evn.target.value)}}/></td>
              </tr>
              <tr><td>Total profit</td><td> </td><td></td><td></td><td></td>
              <td></td>
              <td>${logisticsPrice-logisticsDeductions-otherCost + matchSubtotal}</td>
              </tr>
              </table>
              <div>
            <label>Pipeline Status:</label>
          </div>
          <Select options={statusOption} value={selectedStatusValue} onChange={e => setSelectedStatusValue(e)} />  
          <div>
            <label>Next Step:</label>
          </div>
          <input type="text" style={resetStyle} value={nextSteps} onChange={evn=>{setNextSteps(evn.target.value)}}/>
          <div>
            <label>Equipment name:</label>
          </div>
          <input type="text" style={resetStyle} value={equipmentName} onChange={evn=>{setEquipmentName(evn.target.value)}}/>
          <div>
            <label>Buyer Rep:</label>
          </div>
          <input type="text" style={resetStyle} value={buyerRep} onChange={evn=>{setBuyerRep(evn.target.value)}}/>
          <div>
            <label>Seller Rep:</label>
          </div>
          <input type="text" style={resetStyle} value={sellerRep} onChange={evn=>{setSellerRep(evn.target.value)}}/>
          <div>
            <label>Reason Lost:</label>
          </div>
          <Select options={reasonOption} value={selectedReasonValue} onChange={e => setSelectedReasonValue(e)} />  
          <div>
            <label>Note:</label>
          </div>
          <input type="text" style={resetStyle} value={note} onChange={evn=>{setNote(evn.target.value)}}/> 
          <hr />
          <div>
            <label>Internal files:</label>
          </div>
          
          <div >
          {/* <label className="image-upload">Upload image</label> */}
          <div className="text-in-form">
            {/* <FileUploader images={images} setImages={setImages}></FileUploader> */}
            <InternalFileUpload initialImages={internalFilesUrls} onImagesChange={setInternalFilesUrls} type="internal" />
          </div>
          </div>
          
          
        
            <button type="submit" className="btn btn-primary" onClick={saveDetails}>
              Save
            </button>
          </div>
        </form>
      )}
    </div>
  )
}

export default PipeLineDetails
