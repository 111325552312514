import React, { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone"
import axios from "axios"
import { Margin } from "@mui/icons-material"
import AppContext from "../AppContext"
import { useDispatch, useSelector } from "react-redux"
import imageCompression from 'browser-image-compression';



const FileUpload = ({ initialImages, onImagesChange, type }) => {
  const [uploadedImages, setUploadedImages] = useState([])
  const acceptedFilesType = type==="internal"?".docx,.doc,image/*,audio/*,video/*,.xls":type==="external"?".docx,.doc,image/*,.xls":"image/*"
  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }
  function isImage(fileName){
    
       // Define a list of image file extensions
       const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'tiff', 'svg'];

       // Extract the file extension from the fileName parameter
       const extension = fileName.split('.').pop().toLowerCase();
   
       // Check if the extracted extension is in the list of image extensions
       return imageExtensions.includes(extension);
   }
  useEffect(() => {
    // Set initial images when the component mounts
    setUploadedImages(initialImages || [])
  }, [initialImages])

  const onDrop = async acceptedFiles => {
    // Simulate saving files to the backend and getting URLs
    // const uploadedUrls = await saveToBackend(acceptedFiles)
    const uploadedUrls = await Promise.all(acceptedFiles.map(compressAndUploadImage))
    
    // Update the state with the new URLs
    setUploadedImages([...uploadedImages, ...uploadedUrls])

    // Notify the parent component about the change
    onImagesChange([...uploadedImages, ...uploadedUrls])
  }

  // Options for the image compression
  const options = {
    maxSizeMB: 2, // Maximum file size (in MB)
    maxWidthOrHeight: 1920, // Maximum width or height in pixels
    useWebWorker: true // Use a web worker for processing to avoid freezing the UI
  };

  // Compress images and then upload them
  const compressAndUploadImage = async (file) => {
    try {
      const compressedFile = await imageCompression(file, options);
      return uploadImage(compressedFile); // Assuming `uploadImage` is a function that uploads and returns a URL
    } catch (error) {
      console.error('Error during image compression:', error);
      throw error; // Handle errors as needed
    }
  };
  const uploadImage = async file => {
    //alert(isImage(file.name))
    try {
      const formData = new FormData()
      formData.append("image", file)

      const data = new FormData()
      data.append("file", file)
      data.append("upload_preset", "adsimage")
      let url = type==="internal"?`${AppContext.apiUrl}/api/internalFile/upload`:"https://api.cloudinary.com/v1_1/dmcaw0vsd/image/upload"
      //file type is images
      //const res = await axios.post("https://api.cloudinary.com/v1_1/elmelm/image/upload", data)
      

      if(type==="internal") {
        const res = await axios.post(url, data,config)
        if(!res.data.includes("http")) {
          let tmp_url = `${AppContext.apiUrl}/api/file/${res.data}`
          return tmp_url

        }
      }
      const res = await axios.post(url, data)
      if (res.data.secure_url) return res.data.secure_url

      //if file is not image, then call api


      // if (response.ok) {
      //   const data = await response.json()
      //   return data.url // Assuming the API returns an object with a 'url' property
      // } else {
      //   console.error("Image upload failed:", response.statusText)
      //   return null
      // }
    } catch (error) {
      console.error("Error uploading image:", error.message)
      return null
    }
  }
  const onRemove = (index, e) => {
    e.preventDefault()
    const updatedImages = [...uploadedImages]
    updatedImages.splice(index, 1)

    // Update the state and notify the parent component about the change
    setUploadedImages(updatedImages)
    onImagesChange(updatedImages)
  }
  const onAddToFirst = (index, event) => {
    event.preventDefault()
    const updatedImages = [...uploadedImages]
    const movedImage = updatedImages.splice(index, 1)[0]
    updatedImages.unshift(movedImage) // Move the image to the first place
    setUploadedImages(updatedImages)
    onImagesChange(updatedImages)
  }

  const saveToBackend = async files => {
    // Simulate saving files to the backend and getting URLs
    // In a real application, you would make an API call to your backend
    // and retrieve the URLs for the saved images.
    // Here, we'll just use a setTimeout to simulate the asynchronous nature.
    return new Promise(resolve => {
      setTimeout(() => {
        const uploadedUrls = files.map(file => URL.createObjectURL(file))
        resolve(uploadedUrls)
      }, 1000)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFilesType
    //maxSize: 20 * 1024 * 1024 // 2 MB limit
  })

  return (

    <div style={{borderColor:"blue",borderWidth:"0.5px",marginLeft:"10px"}}>
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop your files here, or click to select</p>
      </div>

      <div>
        <p>Uploaded {type==="image"?"Images":type==="internal"?"Internal files":"External files"}</p>
        <ul>
          {uploadedImages.map((imageUrl, index) => (
            
            <li key={index} style={liStyles}>
              {type!="image" && (`${imageUrl}
              `)}
              {isImage && type!="internal" && type!="external" && (<img  src={imageUrl} alt={`Image ${index}`} style={imageStyles} />)}
              <div>
                <button style={buttonStyles} onClick={e => onRemove(index, e)}>
                  Remove
                </button>
              </div>
              <div>
                {" "}
                <button style={buttonStyles} onClick={e => onAddToFirst(index, e)}>
                  Set First
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const dropzoneStyles = {
  border: "1px dashed #cccccc",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  marginBottom: "20px"
  
}

const imageStyles = {
  maxWidth: "100%",
  maxHeight: "100px",
  marginRight: "20px"
}

const liStyles = {
  border: "1px dashed #cccccc"
}

const buttonStyles = {
  marginRight: "20px"
}

export default FileUpload
