import { Link, useNavigate } from "react-router-dom"
import "./navbar.css"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"
import { useEffect, useState } from "react"

function Navbar() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [roles,setRoles] = useState(JSON.parse(localStorage.getItem("role")))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })
  const logout = e => {
    e.preventDefault();
    navigate("/admin/login");      // Try navigating first
    dispatch(removeUser());   
  }

  const globalSearch = e => {
    
    navigate("/admin/globalSearch")
  }

  const id = state.user.user.id
  const userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }
  const isAdmin = () => {
    return roles && Array.isArray(roles) && roles.some(role => role.name && role.name !== "sales_rep");
  };
  
  const homeLink = id ?(isAdmin()? "/admin":"/admin/sales") : "/"
  // const roles = localStorage.getItem("role")

  const [searchKey, setSearchKey] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      navigate(`/admin/globalSearch/${searchKey}`);
    }
  };

  return (
    <div className="home-header">
      <div className="home">
        <Link to={homeLink}>
          <i className="bi bi-house-door"></i> 
          <p className="leble-icon-home">Home</p>
        </Link>
      </div>
      {id ? (
        <>
          <div className="add-ads">
            <div className="dropdown">
              <p className="dropbtn">Manage Ads</p>
              <div className="dropdown-content">
                <Link to="/addAds">Create For Sale</Link>
                <Link to="/admin">For Sale</Link>
                <Link to="/addWantedAds">Create Wanted</Link>
                <Link to="/wanted">Wanted</Link>
              </div>
            </div>
          </div>

          
          <div className="add-ads">
          <div className="dropdown">
              <p className="dropbtn">Pipelines</p>
              <div className="dropdown-content">
              <Link to="/admin/addPipeline">
              Add Pipeline
            </Link>
            {roles &&roles.name && roles.name[0]==="sales_rep" &&(
              <Link to="/admin/sales">
              Pipelines
            </Link>
            )}
            {roles &&roles.name && roles.name[0]!=="sales_rep" &&(
              <>
              
            <Link to="/admin/pipelineList">
              Pipelines
            </Link>
            <Link to="/admin/pipelineList/0">
              Grave Yard
            </Link>
            <Link to="/admin/pipelineList/100">
              Completed
            </Link></>
            )}
              </div>
            </div>
            
          </div>
          {roles &&roles.name && roles.name[0]!="sales_rep" &&(
          <div className="add-ads">
          <div className="dropdown">
              <p className="dropbtn">Lead</p>
              <div className="dropdown-content">
              
            <Link to="/admin/lead">
              Lead list
            </Link>
              </div>
            </div>
            
          </div>
          )}
          <div className="editAds">
            <div className="dropdown">
              <button className="dropbtn">Manage Data</button>
              <div className="dropdown-content">
                <Link to="/admin/makes">Makes</Link>
                <Link to="/admin/models">Model</Link>
                <Link to="/admin/category">Category</Link>
                {roles &&roles.name && roles.name[0]!="sales_rep" &&(
                  <>
                <Link to="/admin/company">Company</Link>
                <Link to="/admin/contact">Contact</Link></>
                )}
              </div>
            </div>
          </div>
          {roles &&roles.name && roles.name[0]!="sales_rep" &&(
          <div className="editAds" style={{width:300,height:10}}>
            {/* <Link to="/admin/globalSearch">
              <p className="leble-icon-editAds" >
                Global Search
              </p>
              <div> */}
      <input
        type="text"
        placeholder="Enter search"
        value={searchKey}
        onChange={(e) => setSearchKey(e.target.value)}
        onKeyDown={handleKeyPress} // Detect Enter key press
      />
    {/* </div>
            </Link> */}
          </div>
          )}
          <div className="editAds">
            <Link>
              <p className="leble-icon-editAds" onClick={logout}>
                Logout
              </p>
            </Link>
          </div>
        </>
      ) : (
        <div className="home-login">
          <Link to="/admin/login">
              <i className="bi bi-box-arrow-in-left"></i>
              <p className="leble-icon-login">Login</p>
            </Link>
        </div>
      )}
    </div>
  )
}

export default Navbar
