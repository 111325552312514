import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import axios from "axios"
import CompanyList from "../company/CompanyList"
import Makes from "../productMakes/Makes"
import Models from "../model/Models"
import CompanyContactList from "../companyContact/CompanyContactList"
import Select from "react-select"
import countryList from "react-select-country-list"
import AppContext from "../../AppContext"
import Category from "../category/Category"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import FileUpload from "../FileUpload"
import InternalFileUpload from "../InternalFileUpload"
import ExternalFileUpload from "../ExternalFileUpload"
import { decodeStringToNumber,encodeNumberTo10CharString } from "../../NumberCodec"
import Snackbar from '@mui/material/Snackbar';
import { Alert } from "@mui/material"
import { DateRange } from "@mui/icons-material"
import DownloadAttachment from "./DownloadAttachment"

function EmailDetails(props) {
  const params = useParams() //params.adsId
  const [data, setData] = useState()
  const [adsTitle, setAdsTitle] = useState()
  const [country, setCountry] = useState()
  const [price, setPrice] = useState(0)
  const [adsLocation, setadsLocation] = useState()
  const [adsDescription, setadsDescription] = useState()
  const [adsImage, setadsImage] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [makesOptions, setMakesOptions] = useState()
  const [makesList, setMakesList] = useState()
  const [modelsOptions, setModelsOptions] = useState()
  const [category, setCategory] = useState()
  const [categoryName, setCategoryName] = useState()
  const [contactsOptions, setContactsOptions] = useState()
  const [companyOptions, setCompanyOptions] = useState()
  const [companyList, setCompanyList] = useState()
  const [waferSizeOptions, setWaferSizeOptions] = useState()
  const [selectedWaferSizeValue, setSelectedWaferSizeValue] = useState()
  const [configuration, setConfiguration] = useState()
  const [note, setNote] = useState()
  const [vintage, setVintage] = useState()
  const [serialNumber, setSerialNumber] = useState()
  const [hideFromFrontEnd, setHideFromFrontEnd] = useState()
  const [imagesUrls, setImagesUrls] = useState([])
  const [featuredItem, setFeaturedItem] = useState(false)

  const [internalFilesUrls, setInternalFilesUrls] = useState([])
  const [externalFilesUrls, setExternalFilesUrls] = useState([])

  const [body, setBody] = useState()
  const [date, setDate] = useState()
  const [messageId,setMessageId] = useState()

  const [quantity, setQuantity] = useState()
  const [process, setProcess] = useState()
  const [availableDate, setAvailableDate] = useState()
  const [statusOptions, setStatusOptions] = useState()
  const [selectedStatusValue, setSelectedStatusValue] = useState()
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState()
  const [severity,setSeverity] = useState()
  const [attachments,setAttachments] = useState()

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  function formatCurrency(e) {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(e)
  }

  const [value, setValue] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  

  useEffect(()=>{
    setDate(localStorage.getItem("emailDate"))
    setMessageId(localStorage.getItem("emailId"))
    
  },[])


  useEffect(()=>{
  if(messageId){axios.get(`${AppContext.apiUrl}/api/admin/gmailtool/emailDetails/${messageId}`, config).then(res=>{
      
      if(res&&res.data){
        setBody(res.data.body)
        setAttachments(res.data.attachmentIdAndFileName)
      }
    })
  }
  },[])

  useEffect(()=>{
    if(messageId){axios.get(`${AppContext.apiUrl}/api/admin/gmailtool/emailDetails/${messageId}`, config).then(res=>{
        
        if(res&&res.data){
          setBody(res.data.body)
          setAttachments(res.data.attachmentIdAndFileName)
        }
      })
    }
    },[messageId])

  return (
    <div >
      <Snackbar open={open} autoHideDuration={6000} onClose={()=>setOpen(false)}>
        <Alert onClose={()=>setOpen(false)} severity={severity} sx={{ width: '100%' }}>
        {message}
        </Alert>
      </Snackbar>
      <Navbar />
      <div style={{"margin":"20px"}}>
      <div >
              <label>Date</label>: {date}
            </div>
            <div>
              <label>Message</label>: <div dangerouslySetInnerHTML={{ __html: body }} />
            </div>
            
              <div>
                <hr></hr>
              <label>Email Attachments</label>
              <ul>
                  {attachments && attachments.map((attachment, index) => (
                      <li key={index}>
                          <DownloadAttachment 
                              messageId={messageId}
                              attachmentId={attachment.id}
                              fileName={attachment.fileName} 
                          />
                      </li>
                  ))}
              </ul>
              </div>
            
            
            
</div>
    </div>
  )
}

export default EmailDetails
