import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../home/home.css"
import axios from "axios"
import { Stack } from "@mui/material"
import { Autocomplete, Box, Button, Checkbox, Collapse, Divider, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from "@mui/material"
import { Add, Remove } from "@mui/icons-material"
import MUIDataTable from "mui-datatables"
import { Avatar } from "@material-ui/core"
import AppContext from "../../AppContext"
import { FormGroup, FormLabel, FormControlLabel, Grid } from "@mui/material"

import Menu from "@mui/material/Menu"
import MakeList from "../productMakes/MakeList"
import Navbar from "../navbar/Navbar"
import PipelinesList from "./PipelinesList"
import { useParams } from "react-router-dom"

function ManagePipeLine() {
  //start search filter
  //for make filter
  const params = useParams()
  const [filterText, setFilterText] = useState("")
  const filters = [
    {
      label: "Make",
      type: "dropdown",
      options: ["3D PLUS", "3M", "AB SCIEX / APPLIED BIOSYSTEMS / ABI / MDS SCIEX"]
    },
    {
      label: "Model",
      type: "autocomplete",
      options: ["PI 9500", "9500xR", "xR80", "Quantum", "Quantum Leap"]
    },
    {
      label: "Category",
      type: "autocomplete",
      options: ["Ion Implanters"]
    }
    // {
    //   label: "Screen size",
    //   type: "checkbox",
    //   options: ["13in", "14in", "15.6in", "Over 15.6in"]
    // },
    // {
    //   label: "Price",
    //   type: "checkbox",
    //   options: ["< $1000", "$1000-$1400", "$1400-$2000", "> $2000"],
    //   required: true
    // },
    // {
    //   label: "Frame refresh rate",
    //   type: "checkbox",
    //   options: ["61-99 Hz", "100-120 Hz", "121-144 Hz", "145-240 Hz"]
    // },
    // {
    //   label: "RAM",
    //   type: "checkbox",
    //   options: ["< 2GB", "2-4 GB", "4-8 GB", "8-16 GB"]
    // }
  ]

  const [openFilters, setOpenFilters] = React.useState({})
  const [filterValues, setFilterValues] = React.useState({})

  const handleToggleFilter = filterLabel => {
    setOpenFilters(prevOpenFilters => ({
      ...prevOpenFilters,
      [filterLabel]: !prevOpenFilters[filterLabel]
    }))
  }

  const handleFilterChange = event => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [event.target.name]: event.target.value
    }))
  }

  const handleAutocompleteChange = (event, value, filterLabel) => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [filterLabel]: value
    }))
  }

  const handleCheckboxChange = event => {
    setFilterValues(prevFilterValues => ({
      ...prevFilterValues,
      [event.target.name]: event.target.checked ? [...(prevFilterValues[event.target.name] || []), event.target.value] : prevFilterValues[event.target.name].filter(value => value !== event.target.value)
    }))
  }

  const handleResetAll = () => {
    setOpenFilters({})
    setFilterValues({})
  }

  useEffect(() => {
    console.log("filter value:", filterValues)
    console.log("filter value price:", filterValues["Price"])

    //   products.filter((product) =>
    //   product.category.toLowerCase().includes(filter.toLowerCase())
    // )
    data.filter(ads => ads.ca)
  }, [filterValues])
  //end

  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })

  const id = state.user.user.id
  const userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const searchKeyword = e => {
    setKeyword(e.target.value)
  }

  function transformArray(arr) {
    return arr.map(item => (typeof item === "object" && item !== null && "name" in item ? item.name : item))
  }

  const search = e => {
    e.preventDefault()

    // axios
    //   .get(`http://localhost:8081/api/ads/search/${keyword}`)
    //   .then(response => {
    //     setData(response.data)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
  }

  

  const goToMake = e => {
    e.preventDefault()
    navigate("/admin/make")
  }
  

  const logout = e => {
    const action = removeUser()
    dispatch(action)
    navigate("/")
  }

  {
    /* test start */
  }

  return (
    <div>
      <Navbar />

      <div className="search">
        {/* <form className="example">
          <input type="text" placeholder="..something in mind? " onChange={searchKeyword} />
          <button>
            <i className="bi bi-search" onClick={search}></i>
          </button>
        </form> */}
      </div>
      <div className="category-nav">
        {/* <ul>
          <button className="btn btn-secondary home-button">
            <i onClick={all}>All</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={buying}>buying</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={selling}>selling</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={car}>front end equipments</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={realEstate}>backend equipments</i>
          </button>
          <button className="btn btn-secondary home-button">
            <i onClick={devices}>Devices</i>
          </button>
        </ul> */}
      </div>

      {/* <LeftSidebarFilter />
        <ProductTable /> */}
      {/* <Box sx={{ bgcolor: "#F5F5F5", width: "300px", p: "24px", marginLeft: "20px" }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Box fontSize="h6.fontSize" fontWeight="bold">
              Filters
            </Box>
            <Button variant="text" color="primary" onClick={handleResetAll}>
              Reset All
            </Button>
          </Box>
          <List>
            {filters.map((filter, index) => (
              <React.Fragment key={filter.label}>
                <ListItem button onClick={() => handleToggleFilter(filter.label)}>
                  <ListItemText primary={filter.label} />
                  {openFilters[filter.label] ? <Remove sx={{ color: filter.required ? "red" : undefined }} /> : <Add sx={{ color: filter.required ? "red" : undefined }} />}
                </ListItem>
                <Collapse in={openFilters[filter.label]} timeout="auto" unmountOnExit>
                  {filter.type === "autocomplete" && (
                    <ListItem sx={{ pl: 4 }}>
                      <Autocomplete
                        options={filter.options}
                        renderInput={params => <TextField {...params} label={filter.label} fullWidth />}
                        onChange={(event, value) => handleAutocompleteChange(event, value, filter.label)}
                        sx={{
                          width: "100%",
                          "& .MuiOutlinedInput-root": {
                            border: "none"
                          }
                        }}
                      />
                    </ListItem>
                  )}
                  {filter.type === "select" && (
                    <ListItem sx={{ pl: 4 }}>
                      <FormControl fullWidth>
                        <InputLabel>{filter.label}</InputLabel>
                        <Select label={filter.label} name={filter.label} value={filterValues[filter.label] || ""} onChange={handleFilterChange}>
                          {filter.options.map(option => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ListItem>
                  )}
                  {filter.type === "checkbox" && (
                    <List component="div" disablePadding>
                      {filter.options.map(option => (
                        <ListItem key={option} button sx={{ pl: 4 }}>
                          <Checkbox
                            checked={(Array.isArray(filterValues[filter.label]) && filterValues[filter.label].includes(option)) || false}
                            onChange={handleCheckboxChange}
                            name={filter.label}
                            value={option}
                            sx={{
                              "&.Mui-checked": {
                                color: "red"
                              }
                            }}
                          />
                          <ListItemText primary={option} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </Collapse>
                {index < filters.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Box> */}

      <div className="all-ads">
        {/* test start */}
        <PipelinesList status={params.status }/>
        {/* <MUIDataTable title={""} data={data} columns={columns} options={options} /> */}
        <p></p>
        {/* test end */}
      </div>
    </div>
  )
}

export default ManagePipeLine
