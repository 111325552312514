import { Button } from "react-bootstrap"
import { icon } from "bootstrap"
import Navbar from "../navbar/Navbar"
import "bootstrap-icons/font/bootstrap-icons.css"
import { addUser } from "../../reducers/user/user"
import { Link, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"
import axios from "axios"
import "./editUserInfo.css"
import AppContext from "../../AppContext"

function EditUserInfo() {
  const [name, setName] = useState()
  const [PhoneNumber, setUserPhoneNumber] = useState()
  const [password1, setPassword1] = useState()
  const [password2, setPassword2] = useState()
  const [data, setData] = useState()
  const navigate = useNavigate()
  let result = false
  const dispatch = useDispatch()
  const successMsg = document.querySelector(".success-msg-edit")

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })

  const id = state.user.user.id
  const userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const userName = e => {
    setName(e.target.value)
  }

  const userPhoneNumber = e => {
    setUserPhoneNumber(e.target.value)
  }

  const userPassword1 = e => {
    setPassword1(e.target.value)
  }

  const userPassword2 = e => {
    setPassword2(e.target.value)
  }

  const deleteUser = e => {
    e.preventDefault()

    axios.delete(`${AppContext.apiUrl}/api/user/${id}`, config).catch(error => console.log(error))

    const action = removeUser()
    dispatch(action)
    navigate("/admin")
  }

  const verificationUserName = e => {
    e.preventDefault()

    const data = {
      name: name
    }

    axios.put(`${AppContext.apiUrl}/api/user/edit/name/${id}`, data, config).catch(error => console.log(error))

    successMsg.style.opacity = 1
  }

  const verificationUserPhoneNumber = e => {
    e.preventDefault()

    const data = {
      phone: PhoneNumber
    }

    axios.put(`${AppContext.apiUrl}/api/user/edit/phone/${id}`, data, config).catch(error => console.log(error))

    successMsg.style.opacity = 1
  }

  const verificationUserPassword = e => {
    e.preventDefault()

    if (password1 == password2) {
      let data = {
        password: password1
      }

      axios.put(`${AppContext.apiUrl}/api/user/edit/password/${id}`, data, config).catch(error => console.log(error))

      successMsg.style.opacity = 1
      navigate("/admin")
    } else {
      alert("passwords do not match")
    }
  }

  return (
    <div>
      <Navbar />
      <div className="wrapper">
        <div>
          <button type="submit" className=" btn btn-danger" onClick={deleteUser}>
            {"Delete user"}
          </button>
        </div>
        <div className="success-msg-edit">
          <p>
            {" "}
            <b>!</b>password changed!{" "}
          </p>
        </div>
        <div id="formContent">
          <h2 className="active"></h2>
          <form>
            <input type="text" placeholder="" value={name} onChange={userName} />
            <button type="submit" className="button" onClick={verificationUserName}>
              change name
            </button>
          </form>
          <form>
            <input type="text" placeholder="" value={PhoneNumber} onChange={userPhoneNumber} />
            <button type="submit" className="button" onClick={verificationUserPhoneNumber}>
              Change phone
            </button>
          </form>
          <tr>
         <div>
         
password:<input type="password" placeholder="" onChange={userPassword1} />
</div>
</tr>
<tr>
<div >repeat:<input type="password" id="input-password2" placeholder="" onChange={userPassword2} />
            </div>
   </tr>         
            
            <div >
            <button type="submit" className="button" onClick={verificationUserPassword}>
              Change password
            </button></div>
            
            
          
        </div>
      </div>
    </div>
  )
}

export default EditUserInfo
