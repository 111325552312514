import React, { useState, useEffect } from "react"
import "./requestForm.css"
import axios from "axios"
import AppContext from "../../AppContext"

function RequestForm(props) {
  {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [message, setMessage] = useState("")

    function handleSend(e) {
      e.preventDefault()
      // Code to handle login goes here
      const listingNumber = props.listingNumber
      let data = {
        email: email,
        phone: phone,
        listingNumber: listingNumber + ", the url is " + props.adsUrl + ", and the message from the guest is " + message,
        name: username
      }

      console.log(data)

      axios.post(`${AppContext.apiUrl}/api/user/sendEmail`, data).catch(error => console.log(error))

      props.toggle()
    }

    return (
      <div className="popup">
        <div className="popup-inner">
          <h2>Please let us know your information and we will get back to you shoartly!</h2>
          <form onSubmit={handleSend}>
            <label>
              Name:
              <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
            </label>
            <label>
              Email:
              <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </label>
            <label>
              Phone:
              <input type="phone" value={phone} onChange={e => setPhone(e.target.value)} />
            </label>
            <label>
              Message:
              <input type="text" value={message} onChange={e => setMessage(e.target.value)} />
            </label>
            <button type="submit">Send</button>
          </form>

          <button id="x" onClick={props.toggle}>
            Close
          </button>
        </div>
      </div>
    )
  }
}

export default RequestForm
