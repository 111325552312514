import React, { useEffect, useState } from "react"
import Select from "react-select"
import axios from "axios"
import { useSelector } from "react-redux"
import AppContext from "../../AppContext"
import { encodeNumberTo10CharString } from "../../NumberCodec"

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" }
// ]

function CompanyList({ options, selectedValue, onSelectChange }) {
  //const [options, setOptions] = useState()
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  const handleSelectChange = event => {
    const value = event
    onSelectChange(value)
  }
  const goToCompanyUrl= (id)=>{
    window.open(AppContext.adminUrl+"company/"+id,'_blank')
  }
  return (<><Select styles={{width:"400px"}} placeholder="company" options={options} value={selectedValue} onChange={handleSelectChange} />
 <a onClick={()=>goToCompanyUrl(selectedValue.value)} style={{textDecoration:"underLine",cursor:"pointer"}} >Go to Company details</a>
 </>
)
}

export default CompanyList
