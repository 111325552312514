import { Link, useNavigate } from "react-router-dom"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { removeUser } from "../../reducers/user/action"
import "bootstrap-icons/font/bootstrap-icons.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "../home/home.css"
import axios from "axios"
import { Stack } from "@mui/material"
import { Autocomplete, Box, Button, Checkbox, Collapse, Divider, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, TextField } from "@mui/material"
import { Add, Remove } from "@mui/icons-material"
import MUIDataTable from "mui-datatables"
import { Avatar } from "@material-ui/core"
import AppContext from "../../AppContext"
import { FormGroup, FormLabel, FormControlLabel, Grid } from "@mui/material"
import { randomCreatedDate, randomTraderName, randomInt, randomArrayItem } from "@mui/x-data-grid-generator"

import Menu from "@mui/material/Menu"
import ModelList from "../model/ModelList"
import Makes from "../productMakes/Makes"
import Category from "../category/Category"
import Navbar from "../navbar/Navbar"

import ExcelImportw from "./ExcelImportw"

function MassUploadW() {
  const [filterText, setFilterText] = useState("")
  const [contactName, setContactName] = useState("")
  
  const [contactPhone, setContactPhone] = useState("")
  const [contactEmail, setContactEmail] = useState("")

  const [selectedMakeValue, setSelectedMakeValue] = useState()
  const [refreshNeeded, setRefreshNeeded] = useState(false)
  const [data, setData] = useState([])
  const [keyword, setKeyword] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const token = JSON.parse(localStorage.getItem("token"))

  let file = false
  let urls = []

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  const id = state.user.user.id
  const userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  useEffect(async () => {
    await axios(`${AppContext.apiUrl}/api/leads`, config).then(result => {
      // const makes = result.data.map(cat => ({ value: cat.id, label: cat.name }))
      // //console.log("category " + category)
      // setMakesOptions(makes)
    })
  }, [])
  const [filterValues, setFilterValues] = React.useState({})

  useEffect(() => {
    //   products.filter((product) =>
    //   product.category.toLowerCase().includes(filter.toLowerCase())
    // )
    data.filter(ads => ads.ca)
  }, [filterValues])
  //end

  const searchKeyword = e => {
    setKeyword(e.target.value)
  }

  function transformArray(arr) {
    return arr.map(item => (typeof item === "object" && item !== null && "name" in item ? item.name : item))
  }
  // useEffect(async () => {
  //   //check if the user logins, or navigate to home /
  //   const result = await axios.get(`${AppContext.apiUrl}/api/ads`)

  //   const origData = result.data
  //   const convertData = origData.map(e => Object.values(e))
  //   const convertData1 = convertData.map(e => transformArray(e).slice(0, 11))
  //   setData(convertData1)
  // }, [])

  const search = e => {
    e.preventDefault()

    // axios
    //   .get(`http://localhost:8081/api/ads/search/${keyword}`)
    //   .then(response => {
    //     setData(response.data)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   })
  }

  const logout = e => {
    const action = removeUser()
    dispatch(action)
    navigate("/")
  }

  {
    /* test start */
  }

  // const [isFormVisible, setFormVisible] = useState(false)

  // const handleAddRecord = () => {
  //   setFormVisible(true)
  // }

  // const handleCloseForm = () => {
  //   setFormVisible(false)
  //   setContactEmail("")
  //   setContactName("")
  //   setContactPhone("")
  //   setSelectedMakeValue("")
  // }

  // const handleNewSubmit = async e => {
  //   e.preventDefault()
  //   //so the list will be freshed when new added
  //   setRefreshNeeded(false)
  //   let randomModelId = Math.abs(randomInt())
  //   axios
  //     .post(`${AppContext.apiUrl}/api/leads`, { id: randomModelId, contactName: contactName, contactPhone: contactPhone, contactEmail: contactEmail }, config)
  //     .then(
  //       setTimeout(function () {
  //         // Something you want delayed.
  //         setRefreshNeeded(true)
  //       }, 3000)
  //     )
  //     .catch(e => {
  //       console.log("something happend when editing model")
  //     })
  // }

  const categoryOptions = [
    { value: 1, label: 'One' },
    { value: 2, label: 'Two' }
  ];
  
  const makesOptions = [
    { value: 1, label: 'Maker1' },
    { value: 2, label: 'Maker2' }
  ];
  
  const modelOptions = [
    { value: 1, label: 'Model1' },
    { value: 2, label: 'Model2' }
  ];

  return (
    <div>
      <Navbar />
      {/* <div className="search">
        <form className="example">
          <input type="text" placeholder="..something in mind? " onChange={searchKeyword} />
          <button>
            <i className="bi bi-search" onClick={search}></i>
          </button>
        </form>
      </div> */}
      <div className="category-nav"></div>

      <div className="all-ads">
        
        {/* test start */}
        <h1>Mass upload: </h1>
        <ExcelImportw categoryOptions={categoryOptions} makesOptions={makesOptions} modelOptions={modelOptions} />
        {/* <MUIDataTable title={""} data={data} columns={columns} options={options} /> */}

        {/* test end */}
      </div>
    </div>
  )
}

export default MassUploadW
