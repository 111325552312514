import React, { useEffect, useState } from "react"
import Select from "react-select"
import axios from "axios"
import { useSelector } from "react-redux"

// const options = [
//   { value: "chocolate", label: "Chocolate" },
//   { value: "strawberry", label: "Strawberry" },
//   { value: "vanilla", label: "Vanilla" }
// ]

function Makes({ options, selectedValue, onSelectChange }) {
  // const [options, setOptions] = useState()
  const state = useSelector(state => {
    return {
      user: state.userReducer,
      token: state.userReducer.token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }
  // useEffect(async () => {
  //   let result = await axios(`http://localhost:8081/api/makes`)

  //   const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
  //   //console.log("category " + category)
  //   setOptions(category)
  // }, [])

  const handleSelectChange = event => {
    const value = event
    onSelectChange(value)
  }

  return <Select placeholder="select make" options={options} value={selectedValue} onChange={handleSelectChange} />
}

export default Makes
