import React, { useState, useEffect } from "react"
import { Button, Table, Form } from "react-bootstrap"
import * as XLSX from "xlsx"
import axios from "axios"
import AppContext from "../../AppContext"

const ExcelImportw = () => {
  const [excelData, setExcelData] = useState([])
  const [mapping, setMapping] = useState({
    description: "",
    makes: "",
    model: "",
    category: "",
    wafer_size: "",
    serial_number: "",
    process: ""
  })
  const [mappedData, setMappedData] = useState([])
  const [makesOptions, setMakesOptions] = useState([])
  const [modelOptions, setModelOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])

  function uniqueByName(data, key) {
    return [...new Map(data.map(x => [key(x), x])).values()]
  }
  useEffect(() => {
    // Fetch the options from the server when the component mounts
    const fetchOptions = async () => {
      // const makesRes = await axios.get('/api/makes'); // Assume API returns [{ value: 1, label: 'Make 1' }, ...]
      // const modelRes = await axios.get('/api/models');
      // const categoryRes = await axios.get('/api/categories');
      axios(`${AppContext.apiUrl}/api/makes`).then(res => {
        const category = res.data.map(cat => ({ value: cat.id, label: cat.name }))
        //console.log("category " + category)
        setMakesOptions(category)
      })
      //setMakesOptions(makesRes.data);
      // setModelOptions(modelRes.data);
      // setCategoryOptions(categoryRes.data);
      let result = await axios(`${AppContext.apiUrl}/api/category`)
    const categories = uniqueByName(result.data, it => it.name)
    // console.log("----------------" + JSON.stringify(uniqueByName(result.data, it => it.name)))

    const category = categories.map(cat => ({ value: cat.id, label: cat.name }))
    setCategoryOptions(category)
    }

    fetchOptions()
  }, [])

  const handleFileUpload = e => {
    const file = e.target.files[0]
    const reader = new FileReader()

    reader.onload = event => {
      const binaryStr = event.target.result
      const workbook = XLSX.read(binaryStr, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(worksheet)
      setExcelData(jsonData)
    }

    reader.readAsBinaryString(file)
  }

  const handleMappingChange = e => {
    setMapping({
      ...mapping,
      [e.target.name]: e.target.value
    })
  }

  const handleDataEdit = async (index, field, value) => {
    const newData = [...mappedData]
    newData[index][field] = value
    setMappedData(newData)
  //   // If the field is 'makes', fetch the corresponding models
  //   if (field === "makes") {
  //     const selectedMakeId = value
  //     axios(`${AppContext.apiUrl}/api/models/getModelByMakesId?id=${selectedMakeId}`).then(res => {
  //       const modelsList = res.data.map(cat => ({ value: cat[0], label: cat[1] }))
  //       // Create a Set to track unique labels
  //       const uniqueLabels = new Set()
  //       const filteredModelsList = modelsList.filter(model => {
  //         if (uniqueLabels.has(model.label)) {
  //           return false // Skip if the label is already in the Set
  //         } else {
  //           uniqueLabels.add(model.label) // Add new label to the Set
  //           return true // Include this model in the filtered list
  //         }
  //       })
  //       // setModelsOptions(filteredModelsList)
  //       newData[index].modelOptions = filteredModelsList // Store model options for this specific row
  //       newData[index].model = "" // Clear the model selection since options have changed
  //       setMappedData([...newData])
  //     })

  //     // const modelRes = await axios.get(`/api/models/by-make/${selectedMakeId}`);
  //   }else if (field === 'model') {
  //     const selectedMakeId = newData[index].makes;
  //     const selectedModelId = value;
  //     try {
  //      let result = await axios(`${AppContext.apiUrl}/api/category/byMakeModel/${selectedMakeId}/${selectedModelId}`)

  //     //const category = result.data.map(cat => ({ value: cat.id, label: cat.name }))
  //     //console.log("category " + category)
  //     // if (selectedModelValue.value !== 0) {
  //     // setSelectedCategoryValue({ value: result.data.id, label: result.data.name })
  //         //const categoryRes = await axios.get(`/api/category/byMakeModel/${selectedMakeId}/${selectedModelId}`);
  //         newData[index].categoryOptions = [{ value: result.data.id, label: result.data.name }]; // Store category options for this specific row
  //         //newData[index].category = ''; // Clear the category selection since options have changed
  //         const categoryLabelFromApi = result.data.name; // Assume API returns { id: categoryId, label: 'Category Name' }

  //       // Find the category in your existing categoryOptions that matches the label from the API
  //       const matchingCategory = newData[index].categoryOptions.find(
  //           (option) => option.label === categoryLabelFromApi
  //       );

  //       if (matchingCategory) {
  //           newData[index].category = result.data.id; // Set the category field with the matched id
  //       } else {
  //           console.warn('No matching category found for the label returned by the API.');
  //       }

  //         setMappedData([...newData]);
  //     } catch (error) {
  //         console.error('Error fetching categories:', error);
  //     }
  // } else {
  //     setMappedData(newData);
  // }
  }

  const handleMapAndDisplay = () => {
    const data = excelData.map(row => ({
      description: row[mapping.description],
      makes: row[mapping.makes],
      model: row[mapping.model],
      category: row[mapping.category],
      wafer_size: row[mapping.wafer_size],
      serial_number: row[mapping.serial_number],
      process: row[mapping.process]
    }))
    setMappedData(data)
  }

  const handleSubmit = () => {
    mappedData.forEach(async row => {
      await axios.post("/ads", row)
    })
  }


  // this will get the matched makes, but no model yet
//   useEffect(() => {
//     console.log('Mapped Data:', mappedData); // Log the mappedData before processing
//     const updatedData = mappedData.map((row) => {
//         console.log('Processing row:', row); // Log each row before processing
//         if (row.makes && makesOptions) {
//             console.log('Found a maker to map:', row.makes); // Log when a maker is found

//             // Check if row.makes is a string before applying trim
//             const rowMakes = typeof row.makes === 'string' ? row.makes.trim().toLowerCase() : null;

//             const matchingMake = makesOptions.find(option => {
//                 if (option.label === null || rowMakes === null) return false; // Return false instead of undefined
//                 return option.label.trim().toLowerCase() === rowMakes;
//             });

//             if (matchingMake) {
//                 console.log('Matching make found:', matchingMake); // Log the matching make
//                 return { ...row, makes: matchingMake.value };
//             }
//         }
//         return row;
//     });

//     if (JSON.stringify(mappedData) !== JSON.stringify(updatedData)) {
//         console.log('Updating Mapped Data'); // Log when updating mapped data
//         setMappedData(updatedData);
//     }
// }, [mappedData, makesOptions]);





  return (
    <div>
      <h2>Import Excel File</h2>
      <input type="file" onChange={handleFileUpload} />

      {excelData.length > 0 && (
        <div>
          <h3>Column Mapping</h3>
          <Form>
            {Object.keys(mapping).map(key => (
              <Form.Group controlId={`mapping-${key}`} key={key}>
                <Form.Label>{key}</Form.Label>
                <Form.Control as="select" name={key} onChange={handleMappingChange}>
                  <option value="">Select Excel Column</option>
                  {Object.keys(excelData[0]).map(column => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            ))}
          </Form>
          <Button variant="primary" onClick={handleMapAndDisplay}>
            Map and Display Data
          </Button>
        </div>
      )}

      {mappedData.length > 0 && (
        <div>
          <h3>Review and Edit Data</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                {Object.keys(mapping).map(field => (
                  <th key={field}>{field}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {mappedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.keys(mapping).map(field => {
                    if (field === "makes") {
                      return (
                        <td key={field}>
                          <Form.Control as="select" value={row.makes} onChange={e => handleDataEdit(rowIndex, 'makes', e.target.value)}>
    <option value="">Select Make</option>
    {makesOptions.map(option => (
        <option key={option.value} value={option.value}>
            {option.label}
        </option>
    ))}
</Form.Control>

                        </td>
                      )
                    } else if (field === "model") {
                      return (
                        <td key={field}>
                          <Form.Control as="select" value={row[field]} onChange={e => handleDataEdit(rowIndex, field, e.target.value)}>
                            <option value="">Select Model</option>
                            {row.modelOptions &&
                              row.modelOptions.map(option => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                          </Form.Control>
                        </td>
                      )
                    } else if (field === "category") {
                      return (
                        <td key={field}>
                          <Form.Control as="select" value={row.category} onChange={e => handleDataEdit(rowIndex, field, e.target.value)}>
                            <option value="">Select Category</option>
                            {row.categoryOptions && row.categoryOptions.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </Form.Control>
                        </td>
                      )
                    } else {
                      return (
                        <td key={field}>
                          <Form.Control type="text" value={row[field]} onChange={e => handleDataEdit(rowIndex, field, e.target.value)} />
                        </td>
                      )
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="success" onClick={handleSubmit}>
            Submit Data
          </Button>
        </div>
      )}
    </div>
  )
}

export default ExcelImportw
