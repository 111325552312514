import Navbar from "../navbar/Navbar"
import { useParams } from "react-router-dom"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { useSelector } from "react-redux"
import AppContext from "../../AppContext"
import { decodeStringToNumber, encodeNumberTo10CharString } from "../../NumberCodec"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import CompanyList from "../company/CompanyList"
import ContactAdsListMui from "../ads/ContactAdsListMui"
import Select from "react-select"
import Snackbar from "@mui/material/Snackbar"
import { Alert } from "@mui/material"
import { useLocation } from "react-router-dom"
import CustomExpandableList from "../customList/CustomExpandableList"
import CustomizedListItem from "../customList/CustomExpandableList"
import ContactEmailListMui from "../customList/ContactEmailList"
import PipelinesListWithGlobalSearch from "../pipelines/PipelinesListWithGlobalSearch"
import AdsListWithGlobalSearch from "./AdsListWithGlobalSearch"
import AdsListWithGlobalSearchWanted from "./AdsListWithGlobalSearchWanted"
import ContactListWithGlobalSearch from "./ContactListWithGlobalSearch"
import CompanyListWithGlobalSearch from "./CompanyListWithGlobalSearch"
import ContactEmailListGlobalSearch from "./ContactEmailListGlobalSearch"

// "priority": null,
// "nextStep": null,
// "firstName": null,
// "lastName": null,
// "originalName": null,
// "title": null,
// "cellPhone": null,
// "wayWeCommunicate": null,
// "wanted": null,
// "forSale": null,
// "note": null,
// "connectionNote": null,
// "accountManager": null

function GlobalSearch(props) {
  const params = useParams()
  const { searchKey } = useParams();
  const [data, setData] = useState()
  const [priority, setPriority] = useState()
  const [nextStep, setNextStep] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [originalName, setOriginalName] = useState()
  const [title, setTitle] = useState()
  const [cellPhone, setCellPhone] = useState()
  const [wayWeCommunicate, setWayWeCommunicate] = useState()
  const [wanted, setWanted] = useState()
  const [forSale, setForSale] = useState()
  const [note, setNote] = useState()
  const [connectionNote, setConnectionNote] = useState()
  const [accountManager, setAccountManager] = useState()
  const [contactEmail, setContactEmail] = useState(searchKey)
  const [contactEmail2, setContactEmail2] = useState()
  const [contactEmail3, setContactEmail3] = useState()

  const [searchKeyword, setSearchKeyword] = useState()

  const [accountManagerName, setAccountManagerName] = useState()
  const [companyId, setCompanyId] = useState()
  const [companyName, setCompanyName] = useState()

  const [makesOptions, setMakesOptions] = useState([])
  const [selectedMakeValue, setSelectedMakeValue] = useState()

  const [managerOptions, setManagerOptions] = useState()
  const [selectedManagerValue, setSelectedManagerValue] = useState()
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState()
  const [severity, setSeverity] = useState()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  // Get specific query parameters
  const companyIdFromParams = queryParams.get("companyId")
  const [refresh, setRefresh] = useState(false)

  const navigate = useNavigate()

  const token = JSON.parse(localStorage.getItem("token"))

  const state = useSelector(state => {
    return {
      user: state.userReducer,
      //token: state.userReducer.token
      token: token
    }
  })

  let id = state.user.user.id
  let userToken = state.token
  const config = {
    headers: { Authorization: `Bearer ${userToken}` }
  }

  useEffect(()=>{
    setSearchKeyword(searchKey)
  },[searchKey])

  useEffect(()=>{
    setSearchKeyword(searchKey)
  },[])

  // useEffect(async () => {
  //   await axios(`${AppContext.apiUrl}/api/company`, config).then(result => {
  //     const makes = result.data.map(cat => ({ value: cat.id, label: cat.name }))
  //     //console.log("category " + category)
  //     setMakesOptions(makes)
  //     if (companyIdFromParams) {
  //       setSelectedMakeValue(makes.find(option => option.value === (companyIdFromParams ? companyIdFromParams.replace('"', "") : null)))
  //     } else {
  //       if (params.contactId != "0") {
  //         const result = axios(`${AppContext.apiUrl}/api/company/getByContactId/${decodeStringToNumber(params.contactId)}`, config).then(res => {
  //           setCompanyId(res.data.id)
  //           setCompanyName(res.data.name)
  //           setSelectedMakeValue({ value: res.data.id, label: res.data.name })
  //         })
  //       }
  //       //setSelectedMakeValue(makes.find(option => option.value === companyId))
  //     }
  //   })
  // }, [])
  // useEffect(async () => {
  //   if (params.contactId != "0") {
  //     const result = await axios(`${AppContext.apiUrl}/api/contact/${decodeStringToNumber(params.contactId)}`, config)

  //     setData(result.data)
  //     setFirstName(result.data.firstName)
  //     setLastName(result.data.lastName)
  //     setPriority(result.data.priority)
  //     setNextStep(result.data.nextStep)
  //     setOriginalName(result.data.originalName)
  //     setTitle(result.data.title)
  //     setCellPhone(result.data.cellPhone)
  //     setWayWeCommunicate(result.data.wayWeCommunicate)
  //     setWanted(result.data.wanted)
  //     setForSale(result.data.forSale)
  //     setNote(result.data.note)
  //     setConnectionNote(result.data.connectionNote)
  //     setSelectedManagerValue({ value: result.data.accountManagerName, label: result.data.accountManagerName })
  //     setContactEmail(result.data.contactEmail)
  //     setContactEmail2(result.data.contactEmail2)
  //     setContactEmail3(result.data.contactEmail3)
  //     setNextStep(result.data.nextStep)
  //     // setSelectedMakeValue({value:result.data.companyId,label:result.data.companyName})
  //   } else {
  //     const data = {
  //       firstName: firstName,
  //       lastName: lastName,
  //       priority: priority,
  //       nextStep: nextStep,
  //       originalName: originalName,
  //       title: title,
  //       cellPhone: cellPhone,
  //       wayWeCommunicate: wayWeCommunicate,
  //       wanted: wanted,
  //       forSale: forSale,
  //       note: note,
  //       connectionNote: connectionNote,
  //       accountManagerName: selectedManagerValue ? selectedManagerValue.value : null,
  //       contactEmail: contactEmail,
  //       contactEmail2: contactEmail2,
  //       contactEmail3: contactEmail3
  //     }
  //     setData(data)
  //   }
  // }, [])

  // const managersOptions = [
  //   { value: "Masa", label: "Masa" },
  //   { value: "Vivian", label: "Vivian" },
  //   { value: "Alan", label: "Alan" },
  //   { value: "Jurin", label: "Jurin" },
  //   { value: "Open", label: "Open" }
  // ]

  // useEffect(() => {
  //   setManagerOptions(managersOptions)
  //   // Set the default status to 'Masa'
  //   if (params.contactId === "0") {
  //     setSelectedManagerValue(managersOptions[0])
  //   }
  // }, [])

  // useEffect(async () => {
  //   if (params.contactId != "0") {
  //     const result = await axios(`${AppContext.apiUrl}/api/company/getByContactId/${decodeStringToNumber(params.contactId)}`, config)
  //     setCompanyId(result.data.id)
  //     setCompanyName(result.data.name)
  //     setSelectedMakeValue({ value: result.data.id, label: result.data.name })
  //   }
  // }, [])

  // useEffect(async () => {
  //   if (params.contactId != "0") {
  //     const result = await axios(`${AppContext.apiUrl}/api/company/getByContactId/${decodeStringToNumber(params.contactId)}`, config)
  //     setCompanyId(result.data.id)
  //     setCompanyName(result.data.name)
  //     setSelectedMakeValue({ value: result.data.id, label: result.data.name })
  //   }
  // }, [refresh])

  // useEffect(() => {
  //   setCompanyId(selectedMakeValue ? selectedMakeValue.value : null)
  // }, [selectedMakeValue])
  const handleSave = e => {
    //e.preventDefault()
    if (!selectedMakeValue) {
      if (!companyIdFromParams) {
        setMessage("Company is required to create contact!")
        setSeverity("error")
        setOpen(true)
        return
      }
    }
    const data = {
      firstName: firstName,
      lastName: lastName,
      priority: priority,
      nextStep: nextStep,
      originalName: originalName,
      title: title,
      cellPhone: cellPhone,
      wayWeCommunicate: wayWeCommunicate,
      wanted: wanted,
      forSale: forSale,
      note: note,
      connectionNote: connectionNote,
      accountManagerName: selectedManagerValue ? selectedManagerValue.value : null,
      contactEmail: contactEmail,
      contactEmail2: contactEmail2,
      contactEmail3: contactEmail3,
      nextStep: nextStep,
      company: { id: companyId }
      // "priority": null,
      // "nextStep": null,
      // "firstName": null,
      // "lastName": null,
      // "originalName": null,
      // "title": null,
      // "cellPhone": null,
      // "wayWeCommunicate": null,
      // "wanted": null,
      // "forSale": null,
      // "note": null,
      // "connectionNote": null,
      // "accountManager": null
    }
    if (params.contactId != "0") {
      axios
        .put(`${AppContext.apiUrl}/api/contact/${decodeStringToNumber(params.contactId)}/${companyId}`, data, config)
        .then(() => {
          setOpen(true)
          setSeverity("success")
          setMessage("Contact saved successfully.")
          setRefresh(!refresh)
        })
        .catch(e => {
          console.log("something happend when editing contact")
          setOpen(true)
          setSeverity("error")
          setMessage("Something went wrong, please contact the admin for help")
        })
    } else {
      axios
        .post(`${AppContext.apiUrl}/api/company/${companyIdFromParams ? companyIdFromParams : companyId}/contact`, data, config)
        .then(
          //navigate("/admin/contact")
          res => {
            setOpen(true)
            setSeverity("success")
            setMessage("Contact created successfully.")
            navigate(`/admin/contact/${encodeNumberTo10CharString(res.data.id)}`)
            setRefresh(!refresh)
          }
        )
        .catch(e => {
          console.log("something happend when editing contact")
          setOpen(true)
          setSeverity("error")
          setMessage("Something went wrong, please contact the admin for help")
        })
    }
  }


  //email start
  const docs =
    // data.documents  //this coming from a json file
    {
      documents: [
        {
          Id: 1,
          Name: "Category 1",
          Sheets: [
            {
              Id: 1,
              Title: "Title1 "
            },
            {
              Id: 2,
              Title: "Title 2"
            },
            {
              Id: 3,
              Title: "Title 3"
            }
          ]
        }
      ]
    }
  //email end
  
  //pipeline start

//pipeline endd

// for sale start

// for sale end
const handleKeyPress = (e) => {
  if (e.key === 'Enter') {
    setContactEmail(e.target.value)
    setSearchKeyword(contactEmail)
  }
};

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
        <Alert onClose={() => setOpen(false)} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <Navbar />
      {/* {data && ( */}
        <div className="ads-from">
          
        <div style={{ display: "flex", gap: "10px" }}>
  <div style={{ width: "70%" }}>
    <b>Text to search:</b>
    <input
      type="text"
      defaultValue={contactEmail}
      onKeyDown={handleKeyPress}
      onChange={e => setContactEmail(e.target.value)} // Update contactEmail on input change
    />
  </div>
  <button name="Search" onClick={() => setSearchKeyword(contactEmail)}>
    Search
  </button>
</div>
          
          <hr />
          <div>
            <label>Email results:</label>
          </div>
          <div>
            {contactEmail + ":"}
            <hr />
            {contactEmail && <ContactEmailListGlobalSearch contactEmail={searchKeyword} refresh={refresh} />}
            {!contactEmail && <p>No emails</p>}
          </div>
          <div>
          <div>
            <label>Pipelines results:</label>
          </div>
            <hr />
            {searchKeyword && <PipelinesListWithGlobalSearch searchTerm={searchKeyword} 
        onSearchChange={setSearchKeyword}
         />}
          </div>
          <div>
          <label>For sale results:</label>
            <hr />
            {searchKeyword && <AdsListWithGlobalSearch searchTerm={searchKeyword} 
        onSearchChange={setSearchKeyword} />}
          </div>
          <div>
          <label>Wanted results:</label>
            <hr />
            {searchKeyword && <AdsListWithGlobalSearchWanted searchTerm={searchKeyword} 
        onSearchChange={setSearchKeyword} />}
          </div>
          <div>
          <label>Others results:</label>
            <hr />
            Contacts:
            {searchKeyword && <ContactListWithGlobalSearch searchTerm={searchKeyword} 
        onSearchChange={setSearchKeyword} />}
          </div>
          <div>
          
            Companies:
            {searchKeyword && <CompanyListWithGlobalSearch searchTerm={searchKeyword} 
        onSearchChange={setSearchKeyword} />}
          </div>
        </div>
      {/* )} */}
    </div>
  )
}

export default GlobalSearch
