// NumberCodec.js
const CHAR_SET = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
const BASE = CHAR_SET.length;

// Function to encode a number to a 10-character string
export const encodeNumberTo10CharString = (num) => {
  if (num < 1 || num > 100000000) {
    throw new Error("Number out of range (must be between 1 and 100,000,000)");
}

let encoded = '';
while (num > 0) {
    const remainder = num % BASE;
    encoded = CHAR_SET[remainder] + encoded;
    num = Math.floor(num / BASE);
}

return encoded;
};

// Function to decode a 10-character string back to a number
export const decodeStringToNumber = (encodedString) => {
  let num = 0;
    for (let i = 0; i < encodedString.length; i++) {
        num = num * BASE + CHAR_SET.indexOf(encodedString[i]);
    }

    return num;
};

