// import React, { useState } from 'react'
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Collapse from '@material-ui/core/Collapse';
// import ExpandLess from '@material-ui/icons/ExpandLess';
// import ExpandMore from '@material-ui/icons/ExpandMore';
// import Divider from '@material-ui/core/Divider';

// export default function CustomizedListItem({ doc }){
//     const [ open, setOpen ] = useState(false)
//     const handleClick = () => {
//         setOpen(!open)
//     }
    
//     return (
//         <div>
//             <ListItem button key={doc.Id} onClick={handleClick}>
//                 <ListItemText primary={doc.Name} />
//                 {open ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse
//                 key={doc.Sheets.Id}
//                 in={open}
//                 timeout='auto'
//                 unmountOnExit
//             >
//                 <List component='li' disablePadding key={doc.Id}>
//                     {doc.Sheets.map(sheet => {
//                         return (
//                             <ListItem button key={sheet.Id}>
//                                 <ListItemIcon>
//                                     {/* <InsertDriveFileTwoToneIcon /> */}
//                                 </ListItemIcon>
//                                 <ListItemText key={sheet.Id} primary={sheet.Title} />
//                             </ListItem>
//                         )
//                     })}
//                 </List>
//             </Collapse>
//             <Divider />
//         </div>
//     )
// }


// // export default function CustomExpandableList() {
// //     const docs = 
// //     // data.documents  //this coming from a json file
// //     {
// //       "documents": [
// //         {
// //           "Id": 1,
// //           "Name": "Category 1",
// //           "Sheets": [
// //             {
// //               "Id": 1,
// //               "Title": "Title1 "
// //             },
// //             {
// //               "Id": 2,
// //               "Title": "Title 2"
// //             },
// //             {
// //               "Id": 3,
// //               "Title": "Title 3"
// //             }
// //           ]
// //         }
// //       ]
// //     }
// //     return (
// //         <div>
// //             <List component='nav' aria-labelledby='nested-list-subheader'>
// //                 {docs.map(doc => {
// //                     return (
// //                         <CustomizedListItem key={doc.id} doc={doc} />
// //                     )
// //                 })}
// //             </List>
// //         </div>
// //     )
// // }

// // export default CustomizedListItem